import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

export default function () {
  return (
    <div>
      <Skeleton animation="wave" variant="text" height={30} />
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <Skeleton animation="wave" variant="text" />
        <Skeleton animation="wave" variant="text" />
      </div>
      <span style={{ display: 'inline-flex', marginBottom: 40 }}>
        <Skeleton animation="wave" variant="text" width={60} style={{ marginRight: 10 }} />
        <Skeleton animation="wave" variant="text" width={60} style={{ marginRight: 10 }} />
        <Skeleton animation="wave" variant="text" width={60} />
      </span>
    </div>
  )
}
