import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import SetCoverIcon from '@material-ui/icons/Wallpaper'
import EditIcon from '@material-ui/icons/Edit'
import { arrayPullToHead } from '../util/array'
import { imageThumbPlaceholder, imageThumbSizeLG, imageThumbSizeSM } from '../constants/image'
import Button from './Button'
import CaptionUpdateDialog from './CaptionUpdateDialog'
import ImageViewer from './ImageViewer'
import ProgressiveImage from './ProgressiveImage'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1.5),
  },
  card: {
    [theme.breakpoints.down('sm')]: {
      minHeight: 150,
    },
    minHeight: 300,
  },
  media: {
    cursor: 'pointer',
    minHeight: 300,
    background: theme.palette.grey[300],
  },
  action: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-evenly',
    },
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionButton: {
    color: theme.palette.text.secondary,
  },
}))

const initialImageViewerState = {
  src: '',
  caption: '',
}

function PostContentImages({ images, disabled, viewOnly, onChange }) {
  const classes = useStyles()

  const [openDialog, setOpenDialog] = React.useState(false)
  const [imageViewer, setImageViewer] = React.useState(initialImageViewerState)
  const [index, setIndex] = React.useState(null)
  const [imageList, setImageList] = React.useState([])

  React.useEffect(() => {
    setImageList(images)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images])

  const handleSetCover = idx => {
    const ii = arrayPullToHead([...imageList], idx)
    setImageList(ii)
    onChange({ images: ii })

    // scroll transition to top when new cover is selected
    // window.scrollTo(0, 0)
    const anchor = document.querySelector('header')
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  const handleDelete = idx => {
    const ii = [...imageList]
    ii.splice(idx, 1)
    setImageList(ii)
    onChange({ images: ii })
  }

  const handleCaptionUpdate = caption => {
    const ii = [...imageList]
    ii[index].caption = caption
    setImageList(ii)
    onChange({ images: ii })
  }
  const handleOpenDialog = idx => {
    setOpenDialog(true)
    setIndex(idx)
  }
  const handleCloseDialog = () => {
    setIndex(null)
    setOpenDialog(false)
  }

  const handleOpenImageView = idx => {
    const img = images[idx]
    setImageViewer({
      src: `${imageThumbSizeLG}/${img.file_id}`,
      caption: img.caption,
    })
  }

  return (
    <>
      {imageList.map((img, i) => (
        <div className={classes.root} key={img.file_id}>
          <Card className={classes.card}>
            <ProgressiveImage
              className={classes.media}
              onClick={() => handleOpenImageView(i)}
              alt={img.caption}
              preview={`${imageThumbPlaceholder}/${img.file_id}`}
              src={`${imageThumbSizeSM}/${img.file_id}`}
            />

            {/* Image caption */}
            {img.caption !== '' && (
              <CardActions>
                <Typography variant="body2" component="p">
                  {img.caption}
                </Typography>
              </CardActions>
            )}
          </Card>

          {/* Image actions */}
          {!viewOnly && (
            <div className={classes.action}>
              {i !== 0 ? (
                <Button
                  className={classes.actionButton}
                  disabled={disabled}
                  onClick={() => handleSetCover(i)}
                  startIcon={<SetCoverIcon />}>
                  Set as Cover
                </Button>
              ) : null}
              <Button
                className={classes.actionButton}
                disabled={disabled || images.length === 1}
                onClick={() => handleDelete(i)}
                startIcon={<DeleteIcon />}>
                Delete
              </Button>
              <Button
                className={classes.actionButton}
                disabled={disabled}
                onClick={() => handleOpenDialog(i)}
                startIcon={<EditIcon />}>
                Edit Caption
              </Button>
            </div>
          )}
        </div>
      ))}

      {index !== null && (
        <CaptionUpdateDialog
          image={images[index]}
          onSubmit={handleCaptionUpdate}
          open={openDialog}
          onClose={handleCloseDialog}
        />
      )}

      <ImageViewer {...imageViewer} onClose={() => setImageViewer(initialImageViewerState)} />
    </>
  )
}
PostContentImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
  viewOnly: PropTypes.bool,
  onChange: PropTypes.func,
}
PostContentImages.defaultProps = {
  disabled: false,
  viewOnly: false,
  onChange: () => {},
}

export default PostContentImages
