import React from 'react'
import { Outlet } from 'react-router-dom'
import { isOk as isLoggedIn } from '../services/auth'
import ScrollTop from '../components/ScrollTop'

export default function () {
  // Redirect to home page if logged in.
  if (isLoggedIn()) {
    window.location = '/home'
    return null
  }

  return (
    <div>
      <ScrollTop />

      <Outlet />
    </div>
  )
}
