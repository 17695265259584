import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import Notify from '../components/Notify'
import { appNotify } from '../actions'

function AppNotify({ clearNotif, kind, text }) {
  const [open, setOpen] = React.useState(false)

  const navigate = useNavigate()

  React.useEffect(() => {
    if (text === null || text === '') {
      return
    }

    setOpen(true)
  }, [text])

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
    clearNotif()
  }

  if (kind === '' || text === '') {
    return null
  }

  // Catch for authentication error and logged them out.
  if (kind === 'error' && text === 'Authentication error.') {
    navigate('/logout')
    return null
  }

  return (
    <Notify kind={kind} open={open} onClose={handleClose}>
      {text}
    </Notify>
  )
}
AppNotify.propTypes = {
  // Injected props by react-redux.
  clearNotif: PropTypes.func.isRequired,
  // Notify object reducer states.
  kind: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

const mapStateToProps = ({ appNotify: { kind, text } }) => ({
  kind,
  text,
})
const mapDispatchToProps = dispatch => ({
  clearNotif: () => dispatch(appNotify.clear()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AppNotify)
