import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import NotFoundIcon from '@material-ui/icons/NotListedLocation'
import Header from '../components/HeaderBase'
import Heading from '../components/Heading'
import Footer from '../components/Footer'
import Link from '../components/Link'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      marginBottom: '10vh',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '8vh',
    },
    marginBottom: '20vh',
    textAlign: 'center',
  },
  heading: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 45,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '8vw',
      marginTop: theme.spacing(0),
    },
    marginTop: theme.spacing(10),
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
  subHeading: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    fontWeight: 'bold',
    width: 380,
    marginBottom: theme.spacing(1),
  },
}))

function TwitterAuth() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Header variant="seamless" />

      <Container maxWidth="md" disableGutters>
        <Container maxWidth="sm" disableGutters className={classes.content}>
          <Heading className={classes.heading} variant="h3">
            <NotFoundIcon style={{ fontSize: 120 }} color="action" />
            <br />4 0 4
          </Heading>
          <br />
          <Typography className={classes.subHeading} variant="h6">
            Ooops! Sorry but this page does not exist.
          </Typography>
          <br />
          <br />
          <Link to="/">Index page</Link> &middot; <Link to="/login">Login page</Link>
        </Container>
      </Container>

      <Footer />
    </div>
  )
}

export default TwitterAuth
