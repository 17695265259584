import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import MButton from '@material-ui/core/Button'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputBase from '@material-ui/core/InputBase'
import CloseIcon from '@material-ui/icons/Close'
import { useFieldInput } from '../util/useFormInput'
import Button from './Button'
import TagsInput from './TagsInput'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

const dialogTitle = 'Post Update'

function PostUpdateDialog(props) {
  const { post, open, onClose, onSubmit } = props
  const { title, description, tags: defaultTags } = post

  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const [payload, setPayload] = useFieldInput({
    title,
    description,
  })
  const [tags, setTags] = React.useState(defaultTags)

  const handleSubmit = () => {
    onSubmit({ ...payload, tags })
  }

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      {fullScreen ? (
        <AppBar className={classes.appBar} variant="outlined">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {dialogTitle}
            </Typography>
            <MButton autoFocus color="inherit" onClick={handleSubmit}>
              Save
            </MButton>
          </Toolbar>
        </AppBar>
      ) : (
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      )}

      <DialogContent>
        <form>
          <InputBase
            {...setPayload('title')}
            fullWidth
            autoFocus
            placeholder="Post Title"
            style={{ fontSize: 20, fontWeight: 'bold', fontFamily: 'Raleway' }}
          />
          <InputBase
            {...setPayload('description')}
            fullWidth
            multiline
            placeholder="Your description here"
            rows={fullScreen ? null : 2}
          />
          <TagsInput value={tags} onChange={values => setTags(values)} />
        </form>
      </DialogContent>

      {!fullScreen && (
        <DialogActions>
          <Button onClick={onClose} color="primary">
            <strong>Cancel</strong>
          </Button>
          <Button onClick={handleSubmit} color="primary">
            <strong>Save</strong>
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
PostUpdateDialog.propTypes = {
  post: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}
PostUpdateDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onSubmit: () => {},
}

export default PostUpdateDialog
