import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import GridList from '@material-ui/core/GridList'
import Skeleton from '@material-ui/lab/Skeleton'
import Typography from '@material-ui/core/Typography'
import PhotoLibraryIcon from '@material-ui/icons/BurstMode'
import ImageTile from './ImageTile'
import UploadButton from './UploadButton'
import useViewPort from '../util/useViewPort'
import { calcGridCellHeight, calcGridCellSpacing } from '../util/calcGrid'
import Button from './Button'

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    marginTop: theme.spacing(2.5),
  },
  gridList: {
    width: theme.breakpoints.values.md,
  },
  text: {
    marginTop: theme.spacing(2.5),
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(2.5),
    color: theme.palette.text.secondary,
  },
}))

function PostGrid({ variant, posts, loading, error, totalPosts, onLoadMore }) {
  const classes = useStyles()
  const { width } = useViewPort()
  const cellHeight = calcGridCellHeight(width)
  const spacing = calcGridCellSpacing(width)

  // loading state
  if (loading && posts.length === 0) {
    return (
      <div className={classes.grid}>
        <Skeleton
          animation="wave"
          variant="rect"
          height={cellHeight}
          width={cellHeight + spacing * 3}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          height={cellHeight}
          width={cellHeight + spacing * 3}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          height={cellHeight}
          width={cellHeight + spacing * 3}
        />
      </div>
    )
  }

  // error state
  if (error) {
    return (
      <Typography className={classes.text} color="error">
        {error}
      </Typography>
    )
  }

  // empty state
  if (posts.length === 0) {
    return (
      <div className={classes.text}>
        <PhotoLibraryIcon style={{ fontSize: 120, marginBottom: -10, color: '#cecece' }} />
        {variant === 'archive' ? (
          <Typography color="textSecondary" gutterBottom>
            No archived post yet
          </Typography>
        ) : (
          <>
            <Typography color="textSecondary" gutterBottom>
              You don&apos;t have post yet
            </Typography>
            <UploadButton />
          </>
        )}
      </div>
    )
  }

  // good state
  return (
    <div className={classes.grid}>
      <GridList cellHeight={cellHeight} cols={3} spacing={spacing} className={classes.gridList}>
        {posts.map(p => (
          <ImageTile key={p.id} post={p} cols={1} component={Link} to={`/post/${p.id}`} />
        ))}
      </GridList>

      {posts.length < totalPosts && (
        <Button disabled={loading} className={classes.button} onClick={onLoadMore}>
          {loading ? 'Loading...' : 'Load more'}
        </Button>
      )}
    </div>
  )
}
PostGrid.propTypes = {
  variant: PropTypes.string,
  posts: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  error: PropTypes.string,
  totalPosts: PropTypes.number,
  onLoadMore: PropTypes.func,
}
PostGrid.defaultProps = {
  variant: 'default',
  posts: [],
  loading: false,
  error: null,
  totalPosts: 0,
  onLoadMore: () => {},
}

export default PostGrid
