import { useState } from 'react'

function useFormInput(initialValue) {
  const [value, set] = useState(initialValue)

  function handleChange(e) {
    set(e.target.value)
  }

  return {
    value,
    onChange: handleChange,
  }
}

export default useFormInput

export function setValue(input, value) {
  input.onChange({
    target: {
      value,
    },
  })
}

export function useFieldInput(initState) {
  const [payload, setPayload] = useState(initState)

  const handleFieldChange = (setter, field) => e =>
    setter({
      ...payload,
      [field]: e.target.value,
    })

  const fieldInput = field => ({
    value: payload[field],
    onChange: handleFieldChange(setPayload, field),
  })

  return [payload, fieldInput]
}
