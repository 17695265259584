import { POST, PUBLIC_POST } from '../actions/types'
import { createEntityReducer, createSearchReducer, createRequestReducer } from './util'

const initialObjectState = {
  id: '',
  user_id: '',
  short_id: '',
  status: 20,
  title: '',
  description: '',
  cover_image: '',
  images: [],
  tags: [],
  view_count: 0,
  created_at: null,
  updated_at: null,
}

const formatter = res => ({ ...res, tags: res.tags || [] })

export const post = createEntityReducer(initialObjectState, POST, formatter)
export const postSearch = createSearchReducer(POST.SEARCH)

const publicInitialObjectState = {
  ...initialObjectState,
  user: { name: '', username: '', image: '' },
}
export const publicPost = createRequestReducer(publicInitialObjectState, PUBLIC_POST)

export default initialObjectState
