import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import PostContent from '../containers/PostContent'

function UpdatePost() {
  return (
    <div>
      <Header loggedIn />

      <PostContent />

      <Footer />
    </div>
  )
}

export default UpdatePost
