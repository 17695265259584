import React from 'react'
import PropTypes from 'prop-types'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

export default function ImageViewer(props) {
  const { src, caption, onClose } = props
  if (src === '') {
    return null
  }

  return <Lightbox mainSrc={src} imageCaption={caption} onCloseRequest={onClose} />
}
ImageViewer.propTypes = {
  src: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  caption: PropTypes.string,
}
ImageViewer.defaultProps = {
  caption: null,
}
