import moment from 'moment'

export function fromNow(date) {
  const d = moment(date)
  const dc = d.clone()

  if (moment() < dc.add(1, 'day')) {
    return d.fromNow()
  }
  if (moment() < dc.add(1, 'year')) {
    return d.format('MMM DD')
  }
  return d.format('MMM DD, YYYY')
}

export function formatDate(date) {
  return moment(date).format('MMMM DD, YYYY')
}
