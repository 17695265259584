import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import PostUser from '../containers/PostUser'
import { postStatusArchived, postStatusLive } from '../constants/post'
import PostContentDetails from './PostContentDetails'
import PostContentImages from './PostContentImages'
import PostArchivedBanner from './PostArchivedBanner'
import PostColumn from './PostColumn'
import SkeletonPostImages from './SkeletonPostImages'
import SkeletonPostDetails from './SkeletonPostDetails'
import AddPhotosButton from './AddPhotosButton'

const useStyles = makeStyles(theme => ({
  postTexts: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  actionButton: {
    color: theme.palette.text.secondary,
  },
  addPhotosButton: {
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
    },
  },
}))

function PostContent({ post, loading, viewOnly, onChange, onSelect }) {
  const classes = useStyles()

  const handleClickRestore = () => {
    onChange({ status: postStatusLive })
  }

  const isArchived = post.status === postStatusArchived

  return (
    <>
      {/* Archives state banner */}
      {isArchived && <PostArchivedBanner post={post} onRestore={handleClickRestore} />}

      {/* Post content */}
      <Container maxWidth="md" disableGutters>
        <PostColumn>
          {/* Post head */}
          <div className={classes.postTexts}>
            {/* User details */}
            <PostUser datetime={post.created_at} />

            {/* Post details */}
            {loading ? (
              <SkeletonPostDetails />
            ) : (
              <PostContentDetails post={post} viewOnly={viewOnly} onChange={onChange} />
            )}
          </div>

          {/* Post images */}
          {post.images.length === 0 ? (
            <SkeletonPostImages />
          ) : (
            <PostContentImages
              images={post.images}
              onChange={onChange}
              disabled={isArchived}
              viewOnly={viewOnly}
            />
          )}

          {/* Add more photos */}
          <div className={classes.addPhotosButton}>
            <AddPhotosButton
              className={classes.actionButton}
              onSelect={onSelect}
              automount={false}
            />
          </div>
        </PostColumn>
      </Container>
    </>
  )
}
PostContent.propTypes = {
  post: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  viewOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
}
PostContent.defaultProps = {
  loading: false,
  viewOnly: false,
  onChange: () => {},
  onSelect: () => {},
}

export default PostContent
