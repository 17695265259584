import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { profileGet, profileUpdate, appNotify } from '../actions'
import { byteSize, uploadSizeLimitKB } from '../constants/post'
import Component from '../components/SettingsDialog'
import { uploadImage } from '../services/api'

function SettingsDialog(props) {
  const { getProfile, updateProfile, notifyError, error, ...other } = props
  const { profile, onClose } = other

  const [didSubmit, setDidSubmit] = React.useState(false)

  // Get initial data
  React.useEffect(() => {
    getProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Handle update response
  React.useEffect(() => {
    if (error !== null) {
      return
    }
    if (!didSubmit) {
      return
    }

    // Successful update now close the dialog
    onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, error])

  const handleSubmit = async ({ name, file }) => {
    const payload = { name }
    // Process uploading image if file exists
    if (file !== null) {
      if (file.size > uploadSizeLimitKB * byteSize) {
        notifyError(
          `${file.name} file size limit reached ${Math.floor(
            file.size / byteSize
          )} of ${uploadSizeLimitKB} KB`
        )
        return
      }

      try {
        // eslint-disable-next-line no-await-in-loop
        const res = await uploadImage(file)
        payload.image = res.file_id
      } catch (e) {
        notifyError(`error uploading image: ${e}`)
      }
    }

    updateProfile(payload)
    setDidSubmit(true)
  }

  return <Component {...other} onSubmit={handleSubmit} />
}

SettingsDialog.propTypes = {
  getProfile: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired,
  // profile object reducer state
  profile: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.string,
}
SettingsDialog.defaultProps = {
  error: null,
}

const mapStateToProps = ({ profile }) => ({
  profile: profile.data,
  fetching: profile.fetching,
  error: profile.error,
})
const mapDispatchToProps = dispatch => ({
  getProfile: () => dispatch(profileGet.request()),
  updateProfile: payload => dispatch(profileUpdate.request(payload)),
  notifyError: text => dispatch(appNotify.error(text)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingsDialog)
