import { takeLatest, all, call, put } from 'redux-saga/effects'
import * as Api from '../services/api'
import * as Actions from '../actions'
import { VERSION } from '../actions/types'

function* get() {
  try {
    const res = yield call(Api.version)
    yield put(Actions.versionGet.success(res))
  } catch (e) {
    yield put(Actions.versionGet.failure(e.message))
  }
}

export default function* () {
  yield all([takeLatest(VERSION.REQUEST, get)])
}
