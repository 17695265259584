import React from 'react'
import TwitterIcon from '@material-ui/icons/AlternateEmail'
import Button from './Button'
import { twitterAuthURL } from '../services/app'

function LoginButton(props) {
  return (
    <Button
      {...props}
      style={{
        maxWidth: 420,
        fontWeight: 'bold',
      }}
      fullWidth
      component="a"
      href={twitterAuthURL}
      color="primary"
      variant="contained"
      startIcon={<TwitterIcon fontSize="large" />}
      size="large">
      Continue with Google
    </Button>
  )
}

export default LoginButton
