import React from 'react'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreIcon from '@material-ui/icons/MoreVert'
import SettingsDialog from '../containers/SettingsDialog'

function MoreMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [openSettings, setOpenSettings] = React.useState(false)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCloseSettings = () => {
    handleClose()
    setOpenSettings(true)
  }

  return (
    <>
      <IconButton aria-controls="more-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreIcon fontSize="inherit" />
      </IconButton>
      <Menu
        id="header-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={handleClose} component={Link} to="/home">
          Home
        </MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/archives">
          Archives
        </MenuItem>
        <MenuItem onClick={handleCloseSettings}>Settings</MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/logout">
          Logout
        </MenuItem>
      </Menu>

      <SettingsDialog open={openSettings} onClose={() => setOpenSettings(false)} />
    </>
  )
}

export default MoreMenu
