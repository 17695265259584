import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import Typography from '@material-ui/core/Typography'
import ImageCountIcon from '@material-ui/icons/PhotoLibrary'
import ViewCountIcon from '@material-ui/icons/Visibility'
import { imageThumbPlaceholder, imageThumbSizeSM } from '../constants/image'
import ProgressiveImage from './ProgressiveImage'

const useStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
    '&:hover .label': {
      display: 'flex !important',
    },
    '& .label': {
      display: 'none',
    },
  },
  label: {
    display: 'flex',
    '&:hover': {
      display: 'none',
    },
  },
}))

function ImageTile(props) {
  const { post, ...other } = props

  const [showTileBar, setShowTileBar] = React.useState(false)

  const toggleTileBar = () => {
    setShowTileBar(!showTileBar)
  }

  const classes = useStyles()
  return (
    <GridListTile
      {...other}
      className={classes.root}
      onMouseEnter={toggleTileBar}
      onMouseLeave={toggleTileBar}>
      <ProgressiveImage
        preview={`${imageThumbPlaceholder}/${post.cover_image}`}
        src={`${imageThumbSizeSM}/${post.cover_image}`}
        alt={post.cover_image}
        style={{ height: '100%' }}
      />
      <GridListTileBar
        className="label"
        title={post.title}
        subtitle={
          <span style={{ display: 'inline-flex' }}>
            <IconText Icon={ImageCountIcon} text={post.images.length} style={{ marginRight: 16 }} />
            <IconText Icon={ViewCountIcon} text={post.view_count} />
          </span>
        }
      />
    </GridListTile>
  )
}
ImageTile.propTypes = {
  post: PropTypes.object.isRequired,
}

export default ImageTile

function IconText({ Icon, text, style }) {
  const styles = {
    root: {
      ...style,
      display: 'inline-flex',
    },
    icon: {
      marginRight: 6,
    },
  }

  return (
    <span style={styles.root}>
      <Icon fontSize="small" style={styles.icon} />
      <Typography variant="subtitle2">{text}</Typography>
    </span>
  )
}
IconText.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  text: PropTypes.node.isRequired,
  style: PropTypes.object,
}
IconText.defaultProps = {
  style: {},
}
