import React from 'react'
import { Link } from 'react-router-dom'
import UploadIcon from '@material-ui/icons/Add'
import Button from './Button'

export default function () {
  return (
    <Button
      startIcon={<UploadIcon />}
      component={Link}
      to="/new-post"
      variant="contained"
      color="primary">
      <strong>Create Post</strong>
    </Button>
  )
}
