import React from 'react'
import Typography from '@material-ui/core/Typography'
import HeaderPublic from '../components/HeaderPublic'
import Heading from '../components/Heading'
import Container from '../components/Container'
import Footer from '../components/Footer'

function Privacy() {
  return (
    <>
      <HeaderPublic />

      <Container maxWidth="md" disableGutters>
        <Heading variant="h6" gutterBottom>
          <strong>Privacy Policy</strong>
          <Typography variant="caption" color="secondary">
            &nbsp;&mdash;&nbsp;updated June 01, 2020
          </Typography>
        </Heading>
        <br />
        <Content />
      </Container>

      <Footer />
    </>
  )
}

export default Privacy

function Content() {
  return (
    <>
      <div id="section1">
        <p>
          Protecting your private information is our priority. This Statement of Privacy applies to
          fotolink.app and FotoLink and governs data collection and usage. For the purposes of this
          Privacy Policy, unless otherwise noted, all references to FotoLink include fotolink.app.
          The FotoLink website is a photo-sharing application site. By using the FotoLink website,
          you consent to the data practices described in this statement.
        </p>
      </div>
      <div id="section3">
        <p>
          <strong>Collection of your Personal Information</strong>
        </p>
        <p>
          Please keep in mind that if you directly disclose personally identifiable information or
          personally sensitive data through FotoLink&apos;s public message boards, this information
          may be collected and used by others.
        </p>
        <p>
          We do not collect any personal information about you unless you voluntarily provide it to
          us. However, you may be required to provide certain personal information to us when you
          elect to use certain products or services available on the Site. These may include: (a)
          registering for an account on our Site; (b) entering a sweepstakes or contest sponsored by
          us or one of our partners; (c) signing up for special offers from selected third parties;
          (d) sending us an email message; (e) submitting your credit card or other payment
          information when ordering and purchasing products and services on our Site. To wit, we
          will use your information for, but not limited to, communicating with you in relation to
          services and/or products you have requested from us. We also may gather additional
          personal or non-personal information in the future.
        </p>
      </div>
      <div id="section5">
        <p>
          <strong>Sharing Information with Third Parties</strong>
        </p>
        <p>
          FotoLink may sell, rent, or lease customer information to third parties for the following
          reasons:
        </p>
        <p>advertising</p>
        <p>
          FotoLink may share data with trusted partners to help perform statistical analysis, send
          you email or postal mail, provide customer support, or arrange for deliveries. All such
          third parties are prohibited from using your personal information except to provide these
          services to FotoLink, and they are required to maintain the confidentiality of your
          information.
        </p>
        <p>
          FotoLink may disclose your personal information, without notice, if required to do so by
          law or in the good faith belief that such action is necessary to: (a) conform to the
          edicts of the law or comply with legal process served on FotoLink or the site; (b) protect
          and defend the rights or property of FotoLink; and/or (c) act under exigent circumstances
          to protect the personal safety of users of FotoLink, or the public.
        </p>
      </div>
      <div id="section6">
        <p>
          <strong>Tracking User Behavior</strong>
        </p>
        <p>
          FotoLink may keep track of the websites and pages our users visit within FotoLink, in
          order to determine what FotoLink services are the most popular. This data is used to
          deliver customized content and advertising within FotoLink to customers whose behavior
          indicates that they are interested in a particular subject area.
        </p>
      </div>
      <div id="section7">
        <p>
          <strong>Automatically Collected Information</strong>
        </p>
        <p>
          Information about your computer hardware and software may be automatically collected by
          FotoLink. This information can include: your IP address, browser type, domain names,
          access times and referring website addresses. This information is used for the operation
          of the service, to maintain quality of the service, and to provide general statistics
          regarding use of the FotoLink website.
        </p>
      </div>
      <div id="section8">
        <p>
          <strong>Use of Cookies</strong>
        </p>
        <p>
          The FotoLink website may use &quot;cookies&quot; to help you personalize your online
          experience. A cookie is a text file that is placed on your hard disk by a web page server.
          Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are
          uniquely assigned to you, and can only be read by a web server in the domain that issued
          the cookie to you.
        </p>
        <p>
          One of the primary purposes of cookies is to provide a convenience feature to save you
          time. The purpose of a cookie is to tell the Web server that you have returned to a
          specific page. For example, if you personalize FotoLink pages, or register with FotoLink
          site or services, a cookie helps FotoLink to recall your specific information on
          subsequent visits. This simplifies the process of recording your personal information,
          such as billing addresses, shipping addresses, and so on. When you return to the same
          FotoLink website, the information you previously provided can be retrieved, so you can
          easily use the FotoLink features that you customized.
        </p>
        <p>
          You have the ability to accept or decline cookies. Most Web browsers automatically accept
          cookies, but you can usually modify your browser setting to decline cookies if you prefer.
          If you choose to decline cookies, you may not be able to fully experience the interactive
          features of the FotoLink services or websites you visit.
        </p>
      </div>
      <div id="section10">
        <p>
          <strong>Security of your Personal Information</strong>
        </p>
        <p>
          FotoLink secures your personal information from unauthorized access, use, or disclosure.
          FotoLink uses the following methods for this purpose:
        </p>
        <table cellSpacing="0" cellPadding="0">
          <tbody>
            <tr>
              <th width="36px" />
              <th width="24px" />
            </tr>
            <tr valign="top">
              <td nowrap="nowrap" />
              <td nowrap="nowrap">-</td>
              <td>SSL Protocol</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="section12">
        <p>
          When personal information (such as a credit card number) is transmitted to other websites,
          it is protected through the use of encryption, such as the Secure Sockets Layer (SSL)
          protocol.
        </p>
        <p>
          We strive to take appropriate security measures to protect against unauthorized access to
          or alteration of your personal information. Unfortunately, no data transmission over the
          Internet or any wireless network can be guaranteed to be 100% secure. As a result, while
          we strive to protect your personal information, you acknowledge that: (a) there are
          security and privacy limitations inherent to the Internet which are beyond our control;
          and (b) security, integrity, and privacy of any and all information and data exchanged
          between you and us through this Site cannot be guaranteed.
        </p>
      </div>
      <div id="section13">
        <p>
          <strong>Children Under Thirteen</strong>
        </p>
        <p>
          FotoLink does not knowingly collect personally identifiable information from children
          under the age of thirteen. If you are under the age of thirteen, you must ask your parent
          or guardian for permission to use this website.
        </p>
      </div>
      <div id="section16">
        <p>
          <strong>E-mail Communications</strong>
        </p>
        <p>
          From time to time, FotoLink may contact you via email for the purpose of providing
          announcements, promotional offers, alerts, confirmations, surveys, and/or other general
          communication.
        </p>
      </div>
      <div id="section18">
        <p>
          <strong>Changes to this Statement</strong>
        </p>
        <p>
          FotoLink reserves the right to change this Privacy Policy from time to time. We will
          notify you about significant changes in the way we treat personal information by sending a
          notice to the primary email address specified in your account, by placing a prominent
          notice on our site, and/or by updating any privacy information on this page. Your
          continued use of the Site and/or Services available through this Site after such
          modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and
          (b) agreement to abide and be bound by that Policy.
        </p>
      </div>
      <div id="section19">
        <p>
          <strong>Contact Information</strong>
        </p>
        <p>
          FotoLink welcomes your questions or comments regarding this Statement of Privacy. If you
          believe that FotoLink has not adhered to this Statement, please contact FotoLink at:
        </p>
        <p>support@fotolink.app</p>
        <p>Effective as of June 01, 2020</p>
      </div>
    </>
  )
}
