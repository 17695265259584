import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router'
import { connect } from 'react-redux'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
import { postGet, postUpdate, postSearch, appNotify, postClear } from '../actions'
import { uploadImage } from '../services/api'
import { maxImagesPerPost, byteSize, uploadSizeLimitKB } from '../constants/post'
import Component from '../components/PostContent'
import { resizeImage2k } from '../util/image'

function PostContent(props) {
  const {
    getPost,
    updatePost,
    clearPost,
    reloadCache,
    notifySuccess,
    notifyError,
    post,
    fetching,
    error,
  } = props

  const postID = useParams().id

  const [didUpdate, setDidUpdate] = React.useState(false)
  const [shouldReloadPosts, setShouldReloadPosts] = React.useState(false)

  // Get initial post data
  React.useEffect(() => {
    if (error !== null) {
      notifyError(error)
      return
    }

    getPost(postID)

    // eslint-disable-next-line consistent-return
    return () => clearPost()
  }, [getPost, clearPost, notifyError, postID, error])

  // Watch successful post update notification
  React.useEffect(() => {
    if (!didUpdate) {
      return
    }

    notifySuccess('Post changes successfully updated!')
    setDidUpdate(false)

    if (shouldReloadPosts) {
      reloadCache()
      setShouldReloadPosts(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post])

  const handlePostChange = payload => {
    if (isEmpty(payload)) {
      return
    }

    // clear post search cache when status and images were changed
    const hasImage = has(payload, 'images')
    if (has(payload, 'status') || hasImage) {
      setShouldReloadPosts(true)
    }
    // set cover image by getting the first image
    const pp = { ...payload }
    if (hasImage) {
      pp.cover_image = pp.images[0].file_id
    }

    updatePost(postID, pp)
    setDidUpdate(true)
  }

  const handleSelectFiles = async files => {
    // Check number of photos
    const count = files.length + post.images.length
    if (count > maxImagesPerPost) {
      notifyError(`You reach maximum of ${count} / ${maxImagesPerPost} photos per post`)
      return
    }

    const images = [...post.images]
    for (let i = 0; i < files.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      const file = await resizeImage2k(files[i])

      if (file.size > uploadSizeLimitKB * byteSize) {
        notifyError(
          `${file.name} file size limit reached ${Math.floor(
            file.size / byteSize
          )} of ${uploadSizeLimitKB} KB`
        )
        continue
      }

      try {
        // eslint-disable-next-line no-await-in-loop
        const res = await uploadImage(file)
        images.push(res)
        handlePostChange({ ...post, images })
      } catch (e) {
        notifyError(`error uploading image: ${e}`)
      }
    }
  }

  return (
    <Component
      post={post}
      loading={fetching}
      onChange={handlePostChange}
      onSelect={handleSelectFiles}
    />
  )
}
PostContent.propTypes = {
  getPost: PropTypes.func.isRequired,
  updatePost: PropTypes.func.isRequired,
  clearPost: PropTypes.func.isRequired,
  reloadCache: PropTypes.func.isRequired,
  notifySuccess: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired,
  // post object reducer state
  post: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.string,
}
PostContent.defaultProps = {
  error: null,
}

const mapStateToProps = ({ post }) => ({
  post: post.data,
  fetching: post.fetching,
  error: post.error,
})
const mapDispatchToProps = dispatch => ({
  getPost: id => dispatch(postGet.request(id)),
  updatePost: (id, payload) => dispatch(postUpdate.request(id, payload)),
  clearPost: () => dispatch(postClear()),
  reloadCache: () => dispatch(postSearch.reload()),
  notifySuccess: text => dispatch(appNotify.success(text)),
  notifyError: text => dispatch(appNotify.error(text)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PostContent)
