import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import HeaderPublic from '../components/HeaderPublic'
import Heading from '../components/Heading'
import Container from '../components/Container'
import Footer from '../components/Footer'

function Faqs() {
  return (
    <>
      <HeaderPublic />

      <Container maxWidth="md" disableGutters>
        <Heading variant="h6" gutterBottom>
          <strong>Frequently Asked Questions</strong>
        </Heading>
        <br />
        <br />
        <Content />
      </Container>

      <Footer />
    </>
  )
}

function Content() {
  return (
    <>
      <Paragraph>
        <strong>What is FotoLink?</strong>
        <br />
        FotoLink is a web-based tool that lets users create and share customized photo
        albums/collections from virtually anywhere on the web.
      </Paragraph>
      <Paragraph>
        <strong>How does it work?</strong>
        <br />
        From your phone or computer, simply select the images for a collection, pick a cover image,
        and post that image to an online host with our connection tool or permalink. When the key
        image or permalink is clicked by a viewer they will be redirected to the FotoLink album you
        just created. FotoLink lets you create an unlimited number of photo/image collections.
      </Paragraph>
      <Paragraph>
        <strong>How is Fotolink different from Instagram?</strong>
        <br />
        FotoLink was designed to be versatile. It&apos;s an on-the-spot photo-collection creator for
        unique and multi-purposed image sets that links to and launches from social networks,
        instant messaging apps, and even email platforms.
      </Paragraph>
      <Paragraph>
        <strong>Does FotoLink work on Facebook?</strong>
        <br />
        Yes. FotoLink works with any platform that supports the Open Graph Protocol.
      </Paragraph>
      <Paragraph>
        <strong>How many photo albums can I create with FotoLink?</strong>
        <br />
        With FotoLink, you can create as many customized and personal photo albums as you wish.
      </Paragraph>
      <Paragraph>
        <strong>Is there a limit to the number of images in a single collection?</strong>
        <br />
        At present, a single, individual photo collection may contain up to 25-separate images.
      </Paragraph>
      <Paragraph>
        <strong>Can a FotoLink photo collection include video files?</strong>
        <br />
        No. FotoLink does not support video formats at this time.
      </Paragraph>
      <Paragraph>
        <strong>Can FotoLink collections be attached to an email?</strong>
        <br />
        Yes. Simply copy the permalink that&apos;s created for the collection you want to send and
        attach it to the body of the email. Most email platforms will display the cover image
        directly in the body of the email and those that don&apos;t will display the permalink that
        redirects to your custom photo albums on FotoLink.
      </Paragraph>
      <Paragraph>
        <strong>Is FotoLink downloadable as a native mobile app?</strong>
        <br />
        No. At present, our beta-release is a mobile-optimized web application but one that runs on
        all mobile devices. It can be easily be saved to your mobile&apos;s screen as an icon for
        easy access with the, ‘Add to Home screen&apos; feature located on your mobile phone’s
        browser.
      </Paragraph>
    </>
  )
}

function Paragraph({ children }) {
  return (
    <Typography paragraph style={{ marginBottom: 26 }}>
      {children}
    </Typography>
  )
}
Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Faqs
