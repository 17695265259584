import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import { fromNow } from '../util/formatDatetime'
import StickyAvatar from './StickyAvatar'
import { imageResizeSizeAvatar } from '../constants/image'

const useStyles = makeStyles(theme => ({
  profileBlock: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      marginBottom: theme.spacing(3),
    },
  },
  profileAvatarInline: {
    [theme.breakpoints.down('sm')]: {
      display: 'inherit',
      marginRight: theme.spacing(1),
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
    display: 'none',
  },
  profileText: {
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
    },
  },
}))

function PostUser({ user, datetime, loading }) {
  const classes = useStyles()

  return (
    <>
      {/* Sticky avatar for wider screens */}
      <StickyAvatar src={user.image} />

      {loading ? (
        <Skeleton animation="wave" variant="text" height={28} component="span" />
      ) : (
        <div className={classes.profileBlock}>
          {/* User image for smaller screens */}
          <Link to="/home">
            <Avatar
              className={classes.profileAvatarInline}
              src={user.image && `${imageResizeSizeAvatar}/${user.image}`}
            />
          </Link>

          <Typography variant="subtitle1" color="secondary" className={classes.profileText}>
            <strong>{user.name}</strong>&nbsp;
            <Typography variant="subtitle1" color="textSecondary" component="span">
              @{user.username} {datetime && `· ${fromNow(datetime)}`}
            </Typography>
          </Typography>
        </div>
      )}
    </>
  )
}
PostUser.propTypes = {
  user: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  datetime: PropTypes.string,
}
PostUser.defaultProps = {
  loading: false,
  datetime: null,
}

export default PostUser
