import trimEnd from 'lodash/trimEnd'
import * as http from './http'

export const API_URL = process.env.REACT_APP_API_URL
export const CDN_URL = trimEnd(process.env.REACT_APP_CDN_URL, '/')

function apiURL(path) {
  return API_URL + path
}

// API Endpoints
const AUTH_RENEW = '/auth/renew'
const AUTH_TWITTER = '/auth/google/login'
const AUTH_GOOGLE_CALLBACK = '/auth/google/callback'
const AUTH_REVOKE = '/auth/revoke'
const PROFILE = '/me'
const PROFILE_STATS = '/me/stats'
const POST = '/me/posts'
const IMAGES = '/me/images'
const TWEET = '/me/tweet'
const PUBLIC_POST = '/p'
const TRACK = '/t'
const VERSION = '/'

export const authTwitter = (ot, ov) =>
  http.request(http.GET, `${apiURL(AUTH_TWITTER)}?oauth_token=${ot}&oauth_verifier=${ov}`)
export const authGoogle = q => http.request(http.GET, `${apiURL(AUTH_GOOGLE_CALLBACK)}${q}`)
export const authRenew = refreshToken =>
  http.request(http.POST, apiURL(AUTH_RENEW), { refresh_token: refreshToken })
export const authRevoke = refreshToken =>
  http.request(http.POST, apiURL(AUTH_REVOKE), { refresh_token: refreshToken })

export const version = () => http.request(http.GET, apiURL(VERSION))
export const publicPost = shortID => http.request(http.GET, `${apiURL(PUBLIC_POST)}/${shortID}`)

export const userProfile = {
  GET: () => http.authnRequest(http.GET, apiURL(PROFILE)),
  PATCH: profile => http.authnRequest(http.PATCH, apiURL(PROFILE), profile),
}
export const userStats = () => http.authnRequest(http.GET, apiURL(PROFILE_STATS))
export const post = http.baseObjectRequest(apiURL(POST))
export const postSearch = http.baseSearchRequest(apiURL(POST))

export const uploadImage = file => http.uploadFile(apiURL(IMAGES), file)
export const deleteImage = fileID => http.authnRequest(http.DELETE, `${apiURL(IMAGES)}/${fileID}`)
export const tweetPostLink = link => http.authnRequest(http.POST, apiURL(TWEET), { link })

export const trackViewURL = postID => `${apiURL(TRACK)}?t=v&p=${postID}`
export const twitterLoginURL = apiURL(AUTH_TWITTER)
