import {
  action,
  createGenericEntityActions as entityActions,
  createGenericSearchActions as searchActions,
} from './util'

import {
  APP_NOTIFY,
  APP_SETTINGS_GET,
  APP_SETTINGS_SET,
  AUTH,
  VERSION,
  PROFILE,
  POST,
  PUBLIC_POST,
} from './types'

// ---------------------------- Application Actions ----------------------------
export const appNotify = {
  success: text => action(APP_NOTIFY, { kind: 'success', text }),
  error: text => action(APP_NOTIFY, { kind: 'error', text }),
  info: text => action(APP_NOTIFY, { kind: 'info', text }),
  warning: text => action(APP_NOTIFY, { kind: 'warning', text }),
  clear: () => action(APP_NOTIFY, { kind: 'clear', text: '' }),
}

export const appSettings = {
  set: settings => action(APP_SETTINGS_SET, settings),
  get: () => action(APP_SETTINGS_GET),
}

// ---------------------------- Service Actions ----------------------------
export const versionGet = {
  request: () => action(VERSION.REQUEST),
  success: result => action(VERSION.SUCCESS, { result }),
  failure: error => action(VERSION.FAILURE, { error }),
}

export const twitterLoginCallback = {
  request: (oauthToken, oauthVerifier) => action(AUTH.REQUEST, { oauthToken, oauthVerifier }),
  success: result => action(AUTH.SUCCESS, { result }),
  failure: error => action(AUTH.FAILURE, { error }),
}

export const profileGet = {
  request: () => action(PROFILE.GET.REQUEST),
  success: result => action(PROFILE.GET.SUCCESS, { result }),
  failure: error => action(PROFILE.GET.FAILURE, { error }),
}

export const profileUpdate = {
  request: profile => action(PROFILE.UPDATE.REQUEST, { profile }),
  success: result => action(PROFILE.UPDATE.SUCCESS, { result }),
  failure: error => action(PROFILE.UPDATE.FAILURE, { error }),
}

export const publicPostGet = {
  request: shortID => action(PUBLIC_POST.REQUEST, { shortID }),
  success: result => action(PUBLIC_POST.SUCCESS, { result }),
  failure: error => action(PUBLIC_POST.FAILURE, { error }),
}

export const postSearch = searchActions(POST.SEARCH)
export const [postGet, postCreate, postUpdate, postRemove, postClear] = entityActions(POST)
