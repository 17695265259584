import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import TwitterIcon from '@material-ui/icons/AlternateEmail'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Button from '../components/Button'
import { twitterAuthURL } from '../services/app'
import { twitterLoginCallback, appSettings } from '../actions'

function TwitterLogin(props) {
  const { loginTwitter, setAppearance, auth, fetching, error, appearance, ...other } = props

  const navigate = useNavigate()

  React.useEffect(() => {
    if (error !== null) {
      return
    }

    if (auth.user_id !== '') {
      navigate('/home')
      setAppearance(appearance || 'auto')
      return
    }

    const qs = window.location.search
    if (qs !== undefined) {
      loginTwitter(qs, '')
    }
  }, [loginTwitter, setAppearance, appearance, auth, error, navigate])

  if (fetching) {
    return <CircularProgress />
  }

  return error ? (
    <>
      <Button
        {...other}
        component="a"
        href={twitterAuthURL}
        color="primary"
        startIcon={<TwitterIcon />}
        size="large">
        Try again
      </Button>
      <Typography variant="body2" color="error">
        {error}
      </Typography>
    </>
  ) : (
    <Typography>Redirecting..</Typography>
  )
}
TwitterLogin.propTypes = {
  loginTwitter: PropTypes.func.isRequired,
  setAppearance: PropTypes.func.isRequired,
  // auth object reducer state.
  auth: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.string,
  appearance: PropTypes.string,
}
TwitterLogin.defaultProps = {
  error: null,
  appearance: null,
}

const mapStateToProps = ({ auth, appSettings: { appearance } }) => ({
  auth: auth.data,
  fetching: auth.fetching,
  error: auth.error,
  appearance,
})
const mapDispatchToProps = dispatch => ({
  loginTwitter: (token, verifier) => dispatch(twitterLoginCallback.request(token, verifier)),
  setAppearance: appearance => dispatch(appSettings.set({ appearance })),
})

export default connect(mapStateToProps, mapDispatchToProps)(TwitterLogin)
