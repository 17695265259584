function stickyElement(ref, stickyClassName, offset = 0) {
  const cur = ref.current
  if (cur === null) {
    return
  }

  if (window.pageYOffset >= Number(cur.dataset.x)) {
    cur.classList.add(stickyClassName)
  } else {
    cur.classList.remove(stickyClassName)
    // Record last detected offset
    cur.dataset.x = String(cur.offsetTop - offset)
  }
}

export default stickyElement
