import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import NotFoundIcon from '@material-ui/icons/RemoveCircleOutline'
import { trackViewURL } from '../services/api'
import PostUser from './PostUser'
import PostContentDetails from './PostContentDetails'
import PostContentImages from './PostContentImages'
import PostColumn from './PostColumn'
import SkeletonPostDetails from './SkeletonPostDetails'
import SkeletonPostImages from './SkeletonPostImages'
import Ad from './Ad'

const useStyles = makeStyles(theme => ({
  postTexts: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
}))

function PublicPostContent({ post, loading, error }) {
  const classes = useStyles()

  // Check archived state post.
  if (error) {
    return <ArchivedDisplay />
  }

  return (
    <>
      {/* Post content */}
      <Container maxWidth="md" disableGutters>
        <PostColumn>
          {/* Post head */}
          <div className={classes.postTexts}>
            {/* User details */}
            <PostUser user={post.user} datetime={post.created_at} loading={loading} />

            {/* Post details */}
            {loading ? <SkeletonPostDetails /> : <PostContentDetails post={post} viewOnly />}
          </div>

          {/* Post images */}
          {loading ? <SkeletonPostImages /> : <PostContentImages images={post.images} viewOnly />}

          {/* Ad placement */}
          <Ad />
        </PostColumn>
      </Container>

      {post.id && <img src={trackViewURL(post.id)} alt="" />}
    </>
  )
}
PublicPostContent.propTypes = {
  post: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
}
PublicPostContent.defaultProps = {
  loading: false,
  error: null,
}

export default PublicPostContent

function ArchivedDisplay() {
  return (
    <Container maxWidth="md" disableGutters style={{ minHeight: '40vh', paddingTop: '10vh' }}>
      <Typography align="center" variant="h6">
        <NotFoundIcon style={{ fontSize: 120 }} color="action" />
        <br />
        This post does not exist
      </Typography>
    </Container>
  )
}
