import React from 'react'
// import Theme from '../components/Theme'
import Router from '../components/Router'
import Theme from './ThemeAdaptive'

function Root() {
  return (
    <Theme>
      <Router />
    </Theme>
  )
}

export default Root
