import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
}))

function Cont({ children }) {
  const classes = useStyles()

  return (
    <Container className={classes.root} maxWidth="md" disableGutters>
      {children}
    </Container>
  )
}
Cont.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Cont
