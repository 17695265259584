import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import blue from '@material-ui/core/colors/blue'
import pink from '@material-ui/core/colors/pink'

function ThemeAdaptive({ children, appearance }) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  let mode = appearance || 'light'
  if (mode === 'auto') {
    mode = prefersDarkMode ? 'dark' : 'light'
  }

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: mode,
          primary: blue,
          secondary: pink,
        },
      }),
    [mode]
  )

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}
ThemeAdaptive.propTypes = {
  children: PropTypes.node.isRequired,
  // App settings object reducer states.
  appearance: PropTypes.string.isRequired,
}

const mapStateToProps = ({ appSettings: { appearance } }) => ({
  appearance,
})

export default connect(mapStateToProps)(ThemeAdaptive)
