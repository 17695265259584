import { takeLatest, all, call, put } from 'redux-saga/effects'
import * as Api from '../services/api'
import * as Actions from '../actions'
import { POST, PUBLIC_POST } from '../actions/types'
import {
  searchEntityWithCache,
  reloadSearchEntityWithCache,
  getEntity,
  postEntity,
  patchEntity,
} from './util'

const cacheKey = 'post-search'
const search = searchEntityWithCache.bind(null, cacheKey, Actions.postSearch, Api.postSearch)
const reloadSearch = reloadSearchEntityWithCache.bind(
  null,
  cacheKey,
  Actions.postSearch,
  state => state.postSearch
)

const get = getEntity.bind(null, Actions.postGet, Api.post.GET)
const create = postEntity.bind(null, Actions.postCreate, Api.post.POST)
const update = patchEntity.bind(null, Actions.postUpdate, Api.post.PATCH)

function* publicGet({ shortID }) {
  try {
    const res = yield call(Api.publicPost, shortID)
    yield put(Actions.publicPostGet.success(res))
  } catch (e) {
    yield put(Actions.publicPostGet.failure(e.message))
  }
}

export default function* () {
  yield all([
    takeLatest(POST.SEARCH.REQUEST, search),
    takeLatest(POST.SEARCH.RELOAD, reloadSearch),
    takeLatest(POST.GET.REQUEST, get),
    takeLatest(POST.CREATE.REQUEST, create),
    takeLatest(POST.UPDATE.REQUEST, update),
    takeLatest(PUBLIC_POST.REQUEST, publicGet),
  ])
}
