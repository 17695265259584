import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { profileGet } from '../actions'
import Component from '../components/PostUser'

function PostUser(props) {
  const { getProfile, profile, fetching, error, datetime } = props

  React.useEffect(() => {
    if (error !== null) {
      return
    }

    getProfile()
  }, [getProfile, error])

  return <Component user={profile} datetime={datetime} loading={fetching} />
}
PostUser.propTypes = {
  getProfile: PropTypes.func.isRequired,
  // profile object reducer state
  profile: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.string,
  // post datetime detail
  datetime: PropTypes.string,
}
PostUser.defaultProps = {
  error: null,
  datetime: null,
}

const mapStateToProps = ({ profile }) => ({
  profile: profile.data,
  fetching: profile.fetching,
  error: profile.error,
})
const mapDispatchToProps = dispatch => ({
  getProfile: () => dispatch(profileGet.request()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PostUser)
