import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Heading from '../components/Heading'
import PostGrid from '../containers/PostGrid'
import { postStatusArchived } from '../constants/post'

const useStyles = makeStyles(theme => ({
  heading: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1),
    },
  },
}))

const filter = { status: postStatusArchived, sort: 'updated_at:desc', limit: 9 }

function Archives() {
  const classes = useStyles()

  return (
    <>
      <Header />

      <Container maxWidth="md" disableGutters>
        <Heading variant="h6" className={classes.heading}>
          <strong>Archive</strong>
          <Typography variant="body1">Only archived post will be shown here</Typography>
        </Heading>

        <PostGrid filter={filter} />
      </Container>

      <Footer />
    </>
  )
}

export default Archives
