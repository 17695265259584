import { VERSION } from '../actions/types'
import { createRequestReducer } from './util'

const initialObjectState = {
  production: false,
  version: '',
  hash: '',
  built: null,
}

export const version = createRequestReducer(initialObjectState, VERSION)

export default initialObjectState
