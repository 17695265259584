import * as Auth from '../services/auth'
import { AUTH } from '../actions/types'
import { formatError } from './util'

const initialObjectState = {
  user_id: '',
  refresh_token: '',
  token: '',
  expires_at: null,
}

export function auth(
  state = {
    data: initialObjectState,
    fetching: false,
    error: null,
  },
  action
) {
  const { result } = action
  switch (action.type) {
    case AUTH.REQUEST:
      return {
        ...state,
        fetching: true,
        error: null,
      }
    case AUTH.SUCCESS:
      // Persist auth data.
      Auth.set(result)
      return {
        ...state,
        data: result,
        fetching: false,
        error: null,
      }
    case AUTH.FAILURE:
      return {
        ...state,
        fetching: false,
        error: formatError(action.error),
      }
    default:
      return state
  }
}

export default initialObjectState
