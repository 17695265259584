import React from 'react'
import Container from '@material-ui/core/Container'
import Header from '../components/Header'
import Footer from '../components/Footer'
import ProfileHero from '../containers/ProfileHero'
import PostGrid from '../containers/PostGrid'
import { postStatusLive } from '../constants/post'

const filter = { status: postStatusLive, sort: 'created_at:desc', limit: 9 }

function Home() {
  return (
    <>
      <Header />

      <Container maxWidth="md" disableGutters>
        <ProfileHero />

        <PostGrid filter={filter} />
      </Container>

      <Footer />
    </>
  )
}

export default Home
