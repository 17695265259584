import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { appSettings } from '../actions'

function AppearanceRadio({ setAppearance, settings }) {
  const [value, setValue] = React.useState(settings.appearance)

  const handleChange = event => {
    setValue(event.target.value)
    setAppearance(event.target.value)
  }

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Appearance</FormLabel>
      <RadioGroup
        row
        aria-label="appearance"
        name="appearance"
        value={value}
        onChange={handleChange}>
        <FormControlLabel value="auto" control={<Radio />} label="Auto" labelPlacement="end" />
        <FormControlLabel value="light" control={<Radio />} label="Light" labelPlacement="end" />
        <FormControlLabel value="dark" control={<Radio />} label="Dark" labelPlacement="end" />
      </RadioGroup>
    </FormControl>
  )
}
AppearanceRadio.propTypes = {
  // Injected props by react-redux.
  setAppearance: PropTypes.func.isRequired,
  // App settings object reducer states.
  settings: PropTypes.object.isRequired,
}

const mapStateToProps = ({ appSettings: settings }) => ({
  settings,
})
const mapDispatchToProps = dispatch => ({
  setAppearance: appearance => dispatch(appSettings.set({ appearance })),
})

export default connect(mapStateToProps, mapDispatchToProps)(AppearanceRadio)
