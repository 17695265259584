const maxWidth = 960 // MUI md width size
const smWidth = 600 // MUI sm width size

const gridCellMaxHeight = 300
export function calcGridCellHeight(viewWidth) {
  if (viewWidth < maxWidth) {
    let differ = 3.1
    if (viewWidth < smWidth) {
      differ = 3.2
    }

    return gridCellMaxHeight - ((maxWidth - viewWidth) / differ).toFixed(0)
  }

  return gridCellMaxHeight
}

const defaultGridListSpacing = 4
export function calcGridCellSpacing(viewWidth) {
  if (viewWidth < smWidth) {
    return 1
  }

  return defaultGridListSpacing
}
