import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import TwitterIcon from '@material-ui/icons/AlternateEmail'
import Footer from '../components/Footer'
import Button from '../components/Button'
import Link from '../components/Link'
import Header from '../components/HeaderBase'
import Heading from '../components/Heading'
import { twitterAuthURL } from '../services/app'

const useStyles = makeStyles(theme => ({
  root: {},
  brand: {
    opacity: 0.85,
    margin: theme.spacing(5, 0, 10),
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      marginBottom: '10vh',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '8vh',
      textAlign: 'center',
    },
    margin: 'unset',
    marginBottom: '20vh',
  },
  heading: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 45,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '8vw',
      marginTop: theme.spacing(0),
    },
    marginTop: theme.spacing(10),
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
  subHeading: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    fontWeight: 'bold',
    width: 380,
    marginBottom: theme.spacing(1),
  },
}))

function Index() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Header variant="seamless">
        <Link to="/signup">Don&apos;t have an account?</Link>
      </Header>

      <Container maxWidth="md" disableGutters>
        <Container maxWidth="sm" disableGutters className={classes.content}>
          <Heading className={classes.heading} variant="h3">
            Welcome back!
          </Heading>
          <br />
          <Typography className={classes.subHeading} variant="h6">
            You&apos;ll be taken to Twitter to authenticate.
          </Typography>
          <br />
          <br />
          <Button
            className={classes.button}
            component="a"
            href={twitterAuthURL}
            color="primary"
            variant="contained"
            startIcon={<TwitterIcon />}
            size="large">
            Login with Google
          </Button>
          <Typography variant="body2" color="textSecondary" paragraph>
            By logging in, you agree to our{' '}
            <Link to="/terms" target="_blank">
              Terms of Service
            </Link>
            {' and '}
            <Link to="/policy" target="_blank">
              Privacy Policy
            </Link>
            .
          </Typography>
          <Typography variant="body2" color="textSecondary">
            We ask for email and read/write access permission to make your experience seamless on
            sharing post to Twitter, your email address will be use for recovering your account.
          </Typography>
        </Container>
      </Container>

      <Footer />
    </div>
  )
}

export default Index
