import { APP_NOTIFY, APP_SETTINGS_GET, APP_SETTINGS_SET } from '../actions/types'
import * as Local from '../services/local'

export const appNotify = (state = { kind: '', text: '' }, action) => {
  switch (action.type) {
    case APP_NOTIFY: {
      const { text } = action
      let { kind } = action
      // Fixes the transition of kind when closing notification.
      if (kind === 'clear') {
        kind = state.kind
      }

      return { ...state, kind, text }
    }
    default:
      return state
  }
}

const defaultSettings = { appearance: '' }

const APP_SETTING_KEY = 'app_settings'

// get localstorage setting when auth is present
const storedSettings = Local.get(APP_SETTING_KEY)

const settings = storedSettings || defaultSettings

export const appSettings = (state = settings, action) => {
  switch (action.type) {
    case APP_SETTINGS_SET: {
      const { appearance } = action

      const nextSettings = {
        ...state,
        appearance,
      }
      // save localstorage setting.
      Local.save(APP_SETTING_KEY, nextSettings)

      return nextSettings
    }
    case APP_SETTINGS_GET:
    default:
      return state
  }
}
