import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import ShareIcon from '@material-ui/icons/Share'
import ArchiveIcon from '@material-ui/icons/Archive'
import PublicIcon from '@material-ui/icons/Public'
import { postStatusArchived } from '../constants/post'
import Button from './Button'
import Heading from './Heading'
import ConfirmDialog from './ConfirmDialog'
import PostUpdateDialog from './PostUpdateDialog'
import ShareDialog from './ShareDialog'
import Tag from './Tag'

const useStyles = makeStyles(theme => ({
  tags: {
    marginRight: 8,
    marginBottom: 4,
    textDecoration: 'none',
  },
  action: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-evenly',
    },
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionButton: {
    color: theme.palette.text.secondary,
  },
}))

function PostContentDetails({ post, viewOnly, onChange }) {
  const classes = useStyles()

  const [openShareDialog, setOpenShareDialog] = React.useState(false)
  const [openUpdateDialog, setOpenUpdateDialog] = React.useState(false)
  const [openArchiveDialog, setOpenArchiveDialog] = React.useState(false)

  const [data, setData] = React.useState({})

  React.useEffect(() => {
    onChange(data)
  }, [data, onChange])

  const handleDetailsSubmit = details => {
    setData({ ...data, ...details })
  }

  const handleConfirmedArchived = () => {
    setData({ ...data, status: postStatusArchived })
    setOpenArchiveDialog(false)
  }

  const disabledActions = post.status === postStatusArchived

  return (
    <>
      {/* Post details */}
      <Heading gutterBottom variant="h6">
        <strong>{post.title}</strong>
      </Heading>
      <Typography variant="body1" style={{ lineHeight: 1.3 }}>
        {post.description}
      </Typography>

      {post.tags && (
        <>
          <Typography gutterBottom />
          {post.tags.map(tag => (
            // <Link to={`/tagged/${tag}`} key={tag}>
            //   <Typography
            //     className={classes.tags}
            //     color="textSecondary"
            //     component="span"
            //     variant="body2">
            //     #{tag}
            //   </Typography>
            // </Link>
            <Tag key={tag} className={classes.tags} label={tag} variant="outlined" size="small" />
          ))}
        </>
      )}

      {/* Post details action */}
      {!viewOnly && (
        <>
          <div className={classes.action}>
            <Button
              className={classes.actionButton}
              startIcon={<PublicIcon />}
              disabled={disabledActions}
              component="a"
              target="_blank"
              href={`/p/${post.short_id}`}>
              View
            </Button>
            <Button
              className={classes.actionButton}
              startIcon={<ArchiveIcon />}
              disabled={disabledActions}
              onClick={() => setOpenArchiveDialog(true)}>
              Archive
            </Button>
            <Button
              className={classes.actionButton}
              startIcon={<EditIcon />}
              disabled={disabledActions}
              onClick={() => setOpenUpdateDialog(true)}>
              Edit
            </Button>
            <Button
              className={classes.actionButton}
              startIcon={<ShareIcon />}
              disabled={disabledActions}
              onClick={() => setOpenShareDialog(true)}>
              Share
            </Button>
          </div>
        </>
      )}
      <Typography gutterBottom />

      {/* Action Dialogs */}
      <ConfirmDialog
        open={openArchiveDialog}
        title="Archives this post?"
        onConfirm={handleConfirmedArchived}
        onClose={() => setOpenArchiveDialog(false)}>
        Post will be archived and not available in public.
      </ConfirmDialog>
      <PostUpdateDialog
        post={post}
        open={openUpdateDialog}
        onClose={() => setOpenUpdateDialog(false)}
        onSubmit={handleDetailsSubmit}
      />
      <ShareDialog post={post} open={openShareDialog} onClose={() => setOpenShareDialog(false)} />
    </>
  )
}
PostContentDetails.propTypes = {
  post: PropTypes.object.isRequired,
  viewOnly: PropTypes.bool,
  onChange: PropTypes.func,
}
PostContentDetails.defaultProps = {
  viewOnly: false,
  onChange: () => {},
}

export default PostContentDetails
