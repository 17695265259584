import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import MButton from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import TwitterIcon from '@material-ui/icons/Twitter'
import LinkIcon from '@material-ui/icons/Link'
import CloseIcon from '@material-ui/icons/Close'
import { tweetPostLink } from '../services/api'
import { APP_URL } from '../services/app'
import { imageResizeSizeCover } from '../constants/image'
import useViewPort from '../util/useViewPort'
import Button from './Button'
import Notify from './Notify'

// 600x314 aspect ratio calc
const mediaRatio = 0.52333333333
const mediaWidth = 600
const mediaHeight = mediaWidth * mediaRatio

const useStyles = makeStyles(theme => ({
  root: {},
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  card: {
    borderRadius: 12,
    maxWidth: mediaWidth,
    margin: '0 auto',
  },
  media: {
    // maxHeight: mediaHeight,
    // maxWidth: mediaWidth,
  },
  content: {
    [theme.breakpoints.down('xs')]: {
      padding: '8px !important',
    },
    padding: '10px !important',
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  error: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: theme.palette.error.dark,
  },
  check: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: 'green',
  },
  permalink: {
    marginTop: 20,
  },
}))

const dialogTitle = 'Share your post'

const getPublicPostURL = shortID => {
  return `${APP_URL}/p/${shortID}`
}

const notifInitialState = {
  open: false,
  kind: 'info',
  text: '',
}

function ShareDialog(props) {
  const { post, open, onClose } = props
  const permalink = getPublicPostURL(post.short_id)

  const permalinkRef = React.useRef(null)
  const [didCopyLink, setDidCopyLink] = React.useState(false)
  const [tweetLoading, setTweetLoading] = React.useState(false)
  const [notif, setNotif] = React.useState(notifInitialState)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  // Keeps image aspect ratio
  const sidePadding = 24 * 2
  let { width } = useViewPort()
  width -= sidePadding
  let cardMediaWidth = mediaWidth
  let cardMediaHeight = mediaHeight
  if (mediaWidth > width) {
    cardMediaWidth = width
    cardMediaHeight = width * mediaRatio
  }

  const handleCopyLink = () => {
    setDidCopyLink(true)

    const input = permalinkRef.current.getElementsByTagName('input')[0]
    /* Select the text field */
    input.select()
    input.setSelectionRange(0, 99999) /* For mobile devices */
    /* Copy the text inside the text field */
    document.execCommand('copy')
  }

  const handleTweetLink = () => {
    setTweetLoading(true)
    tweetPostLink(permalink)
      .then(() => {
        setTweetLoading(false)
        setNotif({ open: true, kind: 'success', text: 'Successfully posted on Twitter!' })
      })
      .catch(e => {
        setNotif({ open: true, kind: 'error', text: e.message })
      })
  }

  const handleClose = () => {
    setDidCopyLink(false)
    setNotif(notifInitialState)
    onClose()
  }

  const classes = useStyles()
  return (
    <>
      <Dialog
        scroll="body"
        maxWidth="md"
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        {fullScreen ? (
          <AppBar className={classes.appBar} variant="outlined">
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {dialogTitle}
              </Typography>
              <MButton autoFocus color="inherit" onClick={handleClose}>
                Done
              </MButton>
            </Toolbar>
          </AppBar>
        ) : (
          <DialogTitle id="alert-dialog-title">
            {dialogTitle}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              style={{ float: 'right' }}
              size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </DialogTitle>
        )}

        <DialogContent>
          <Typography color="textSecondary" gutterBottom>
            Your post will look just like this. To add hashtags and hyperlinks on Twitter, use the
            copy link button below.
          </Typography>
          <Card className={classes.card}>
            <div>
              <CardMedia
                className={classes.media}
                style={{ width: cardMediaWidth, height: cardMediaHeight }}
                component="img"
                image={`${imageResizeSizeCover}/${post.cover_image}`}
              />
              <CardContent className={classes.content}>
                <Typography variant="body2">
                  <strong>{post.title}</strong>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ display: fullScreen ? 'none' : 'inherit' }}>
                  {post.description}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  fotolink.app
                </Typography>
              </CardContent>
            </div>
          </Card>

          {/* Permalink field */}
          <TextField
            ref={permalinkRef}
            className={classes.permalink}
            style={
              didCopyLink
                ? null
                : { opacity: 0, position: 'absolute', top: 0, right: 0, zIndex: -1000 }
            }
            color="secondary"
            label="Permalink copied to clipboard!"
            variant="outlined"
            size="small"
            fullWidth
            value={permalink}
          />

          {/* small screen actions */}
          {fullScreen && (
            <DialogActions style={{ padding: '10px 0 0' }}>
              <Button
                onClick={handleCopyLink}
                variant="outlined"
                startIcon={<LinkIcon fontSize="inherit" />}
                size="large"
                fullWidth>
                <strong>Copy Link</strong>
              </Button>
              <Button
                disabled
                onClick={handleTweetLink}
                variant="outlined"
                startIcon={
                  tweetLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <TwitterIcon fontSize="inherit" />
                  )
                }
                size="large"
                color="primary"
                fullWidth>
                <strong>Twitter</strong>
              </Button>
            </DialogActions>
          )}
        </DialogContent>

        {/* large screen actions */}
        {!fullScreen ? (
          <DialogActions>
            <Button
              onClick={handleCopyLink}
              startIcon={<LinkIcon fontSize="inherit" />}
              size="large"
              fullWidth>
              <strong>Copy Link</strong>
            </Button>
            <Button
              onClick={handleTweetLink}
              startIcon={
                tweetLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <TwitterIcon fontSize="inherit" />
                )
              }
              size="large"
              color="primary"
              fullWidth>
              <strong>Twitter</strong>
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>

      <Notify open={notif.open} kind={notif.kind} onClose={() => setNotif(notifInitialState)}>
        {notif.text}
      </Notify>
    </>
  )
}
ShareDialog.propTypes = {
  post: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
ShareDialog.defaultProps = {
  open: false,
  onClose: () => {},
}
export default ShareDialog
