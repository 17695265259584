// IMPORTANT RULE! ONLY used this utility file within its folder.

import { put, call, select } from 'redux-saga/effects'
import querystring from 'querystring'
import * as Local from '../services/local'

// Generic entity search fetch request.
export function* searchEntity(entity, apiFn, { filter }) {
  try {
    const result = yield call(apiFn, filter)
    yield put(entity.success(result))
  } catch (e) {
    yield put(entity.failure(e.message))
  }
}

// Generic reload entity search.
export function* reloadSearchEntity(entity, stateSelector) {
  const { filter } = yield select(stateSelector)
  yield put(entity.request(filter))
}

// Generic single entity get request.
export function* getEntity(entity, apiFn, { id }) {
  try {
    const result = yield call(apiFn, id)
    yield put(entity.success(result))
  } catch (e) {
    yield put(entity.failure(e.message))
  }
}

// Generic single entity post request.
export function* postEntity(entity, apiFn, { payload }) {
  try {
    const result = yield call(apiFn, payload)
    yield put(entity.success(result))
  } catch (e) {
    yield put(entity.failure(e.message))
  }
}

// Generic single entity patch request.
export function* patchEntity(entity, apiFn, { id, payload }) {
  try {
    const result = yield call(apiFn, id, payload)
    yield put(entity.success(result))
  } catch (e) {
    yield put(entity.failure(e.message))
  }
}

// Generic single entity delete request.
export function* deleteEntity(entity, apiFn, { id }) {
  try {
    const result = yield call(apiFn, id)
    yield put(entity.success(result))
  } catch (e) {
    yield put(entity.failure(e.message))
  }
}

function searchCacheKey(cacheKey, filter) {
  return `${cacheKey}/${querystring.stringify(filter)}`
}

const defaultCacheTTL = 60 * 2 // 2 minutes

// Generic entity search fetch request with caching support.
export function* searchEntityWithCache(cacheKey, entity, apiFn, { filter }) {
  const ck = searchCacheKey(cacheKey, filter)

  // Check for cached data.
  let result = Local.get(ck)
  if (result !== null) {
    yield put(entity.success(result))
    return
  }

  // Request for updated one.
  try {
    result = yield call(apiFn, filter)
    // Save it on cache when it has result.
    if (result !== null && result.data.length !== 0) {
      yield Local.save(ck, result, defaultCacheTTL)
    }
    yield put(entity.success(result))
  } catch (e) {
    yield put(entity.failure(e.message))
  }
}

// Generic reload entity search with cache invalidation.
export function* reloadSearchEntityWithCache(cacheKey, entity, stateSelector) {
  const { filter } = yield select(stateSelector)
  const ck = searchCacheKey(cacheKey, filter)

  // Invalidate cache on reload.
  Local.removeAll(ck)
  yield put(entity.request(filter))
}
