import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core/styles'

function ProgressiveImage(props) {
  const theme = useTheme()

  const { preview, src, ...other } = props
  const [view, setView] = React.useState(preview)
  const [loading, setLoading] = React.useState(false)
  const [style, setStyle] = React.useState({
    width: '100%',
    display: 'block',
    objectFit: 'cover',
    filter: 'blur(16px)',
    WebkitTransition: 'all 300ms ease-out',
    MozTransition: 'all 300ms ease-out',
    MsTransition: 'all 300ms ease-out',
    OTransition: 'all 300ms ease-out',
    transition: 'all 300ms ease-out',
    background: theme.palette.divider,
    ...props.style,
  })

  React.useEffect(() => {
    setView(preview)
    if (loading) {
      return
    }

    fetchImage(src)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src])

  const fetchImage = src => {
    setLoading(true)
    const img = new Image()
    img.src = src
    img.onload = () => {
      setLoading(false)
      setView(src)
      setStyle({ ...style, filter: 'none' })
    }
  }

  return <img alt="" loading="lazy" {...other} src={view} style={style} />
}
ProgressiveImage.propTypes = {
  preview: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
}

export default ProgressiveImage

// function isOnScreen(ref) {
//   if (ref.current) {
//     var rect = ref.current.getBoundingClientRect();
//     var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
//     return !(rect.bottom < 0 || rect.top - viewHeight >= 0)
//   }
// }

// function useOnScreen(ref, rootMargin = "0px") {
//   // State and setter for storing whether element is visible
//   const [isIntersecting, setIntersecting] = React.useState(false);

//   React.useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         // Update our state when observer callback fires
//         setIntersecting(entry.isIntersecting);
//       },
//       {
//         rootMargin,
//       }
//     );
//     if (ref.current) {
//       observer.observe(ref.current);
//     }
//     return () => {
//       observer.unobserve(ref.current);
//     };
//   }, []); // Empty array ensures that effect is only run on mount and unmount

//   return isIntersecting;
// }
