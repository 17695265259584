import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import stickyElement from '../util/stickyElement'
import { Link } from 'react-router-dom'
import { imageResizeSizeAvatar } from '../constants/image'

let avatarStickyTopPadding = 0

const useStyles = makeStyles(theme => {
  avatarStickyTopPadding = theme.spacing(1.5)
  return {
    root: {
      [theme.breakpoints.down('sm')]: {
        opacity: 0,
      },
      width: theme.spacing(10),
      height: theme.spacing(10),
      // margin: theme.spacing(0, 1.5, 0, 8.5),
      marginLeft: theme.spacing(-11.5),
      float: 'right',
      position: 'absolute',
    },
    fixed: {
      top: avatarStickyTopPadding,
      position: 'fixed',
    },
  }
})

function StickyAvatar({ src }) {
  const classes = useStyles()

  const avatarRef = React.useRef()
  React.useEffect(() => {
    const listener = () => {
      stickyElement(avatarRef, classes.fixed, avatarStickyTopPadding)
    }

    // Initial location detection
    listener()

    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  })

  return (
    <Link to="/home">
      <Avatar
        className={classes.root}
        ref={avatarRef}
        src={src && `${imageResizeSizeAvatar}/${src}`}
      />
    </Link>
  )
}
StickyAvatar.propTypes = {
  src: PropTypes.string,
}
StickyAvatar.defaultProps = {
  src: null,
}

export default StickyAvatar
