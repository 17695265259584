import React from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import * as Auth from '../services/auth'
import Header from '../components/HeaderBase'
import Footer from '../components/Footer'

function Logout() {
  Auth.clear()
  localStorage.clear()
  window.location = '/'

  return (
    <div>
      <Header variant="seamless" />

      <Container maxWidth="md" disableGutters style={{ minHeight: '40vh', paddingTop: '15vh' }}>
        <Typography align="center" variant="h6">
          Logging you out...
        </Typography>
      </Container>

      <Footer />
    </div>
  )
}

export default Logout
