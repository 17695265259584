import React from 'react'
import { Outlet } from 'react-router-dom'
import ScrollTop from '../components/ScrollTop'

export default function () {
  return (
    <div>
      <ScrollTop />

      <Outlet />
    </div>
  )
}
