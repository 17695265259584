import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Avatar from '@material-ui/core/Avatar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import MButton from '@material-ui/core/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import CloseIcon from '@material-ui/icons/Close'
import { imageResizeSizeAvatar } from '../constants/image'
import AppearanceRadio from '../containers/AppearanceRadio'
import { getImagePreview } from '../util/image'
import FileUploadButton from './FileUploadButton'
import Button from './Button'

const useStyles = makeStyles(theme => ({
  appBar: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
    },
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  avatarContainer: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  avatar: {
    margin: '0 auto',
    width: theme.spacing(18),
    height: theme.spacing(18),
  },
}))

const dialogTitle = 'Settings'

function SettingsDialog(props) {
  const { profile, open, onClose, onSubmit } = props

  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const [name, setName] = React.useState('')
  const [file, setFile] = React.useState(null)
  const [preview, setPreview] = React.useState('')

  const handleSubmit = e => {
    e.preventDefault()

    onSubmit({
      name: name || profile.name,
      file,
    })
  }

  const handleSelect = async files => {
    if (files.length === 0) {
      return
    }
    const f = files[0]
    setFile(f)
    getImagePreview(f, p => setPreview(p))
  }

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      {fullScreen ? (
        <AppBar className={classes.appBar} variant="outlined">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {dialogTitle}
            </Typography>
            <MButton color="inherit" onClick={handleSubmit}>
              Save
            </MButton>
          </Toolbar>
        </AppBar>
      ) : (
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      )}

      <DialogContent>
        <div className={classes.avatarContainer}>
          <Avatar
            className={classes.avatar}
            src={preview || (profile.image && `${imageResizeSizeAvatar}/${profile.image}`)}
          />
          <FileUploadButton accept="image/*" onSelect={handleSelect}>
            <Button component="span" color="primary">
              Change
            </Button>
          </FileUploadButton>
        </div>
        <form onSubmit={handleSubmit}>
          <TextField
            onChange={e => setName(e.target.value)}
            defaultValue={profile.name}
            variant="outlined"
            fullWidth
            required
            label="Profile Name"
          />
        </form>
        <br />
        <AppearanceRadio />
      </DialogContent>

      {!fullScreen && (
        <DialogActions>
          <Button onClick={onClose} color="primary">
            <strong>Cancel</strong>
          </Button>
          <Button onClick={handleSubmit} color="primary">
            <strong>Save</strong>
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
SettingsDialog.propTypes = {
  profile: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}
SettingsDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onSubmit: () => {},
}

export default SettingsDialog
