import { takeLatest, all, call, put } from 'redux-saga/effects'
import * as Api from '../services/api'
import * as Actions from '../actions'
import { AUTH } from '../actions/types'

function* twitterCallback({ oauthToken, oauthVerifier }) {
  try {
    const auth = yield call(Api.authGoogle, oauthToken, oauthVerifier)
    yield put(Actions.twitterLoginCallback.success(auth))
  } catch (e) {
    yield put(Actions.twitterLoginCallback.failure(e.message))
  }
}

export default function* () {
  yield all([takeLatest(AUTH.REQUEST, twitterCallback)])
}
