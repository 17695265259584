import React from 'react'
import Typography from '@material-ui/core/Typography'

export default function Copyright(props) {
  return (
    <Typography {...props} variant="caption" color="textSecondary" paragraph>
      <strong>&copy; {new Date().getFullYear()} FotoLink</strong>
    </Typography>
  )
}
