import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import { imageResizeSizeAvatar } from '../constants/image'

const useStyles = makeStyles(theme => ({
  profile: {
    flexGrow: 1,
  },
  avatar: {
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(16),
      height: theme.spacing(16),
      // margin: theme.spacing(0, 2, 0, 1),
      margin: '0 auto',
    },
    width: theme.spacing(24),
    height: theme.spacing(24),
    margin: 'auto',
  },
  avatarName: {
    fontFamily: 'Raleway',
    fontWeight: 'bold',
  },
  avatarDetails: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
    padding: theme.spacing(2, 1, 0),
  },
}))

function Hero({ profile, loading }) {
  const classes = useStyles()

  if (loading) {
    return (
      <Grid container className={classes.profile}>
        <Grid item sm={4} xs={12}>
          <Skeleton className={classes.avatar} animation="wave" variant="circle" />
        </Grid>
        <Grid item sm={8} xs={12} className={classes.avatarDetails}>
          <Skeleton animation="wave" variant="rect" height={28} className={classes.avatarName} />
          <Skeleton animation="wave" variant="text" height={28} />
          <br />
          <Skeleton animation="wave" variant="text" />
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container className={classes.profile}>
      <Grid item sm={4} xs={12}>
        <Avatar
          className={classes.avatar}
          alt={profile.name}
          src={profile.image ? `${imageResizeSizeAvatar}/${profile.image}` : null}
        />
      </Grid>
      <Grid item sm={8} xs={12} className={classes.avatarDetails}>
        <Typography variant="h5" className={classes.avatarName}>
          {profile.name}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          <strong>@{profile.username}</strong>
        </Typography>

        <br />
        <Typography variant="body1">
          <strong>{profile.stats ? profile.stats.posts : '--'}</strong> posts &nbsp;&nbsp;&nbsp;
          <strong>{profile.stats ? profile.stats.images : '--'}</strong> photos &nbsp;&nbsp;&nbsp;
          <strong>{profile.stats ? profile.stats.views : '--'}</strong> views
        </Typography>
      </Grid>
    </Grid>
  )
}
Hero.propTypes = {
  profile: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default Hero
