import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Alert, AlertTitle } from '@material-ui/lab'
import { formatDate } from '../util/formatDatetime'
import Button from './Button'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(-2.5, 0, 2.5),
    borderRadius: 0,
  },
}))

function ArchivedBanner({ post, onRestore }) {
  const classes = useStyles()

  return (
    <Alert
      className={classes.root}
      severity="warning"
      action={
        <Button color="inherit" onClick={onRestore}>
          <strong>Restore</strong>
        </Button>
      }>
      <AlertTitle>
        <strong>Archived Post</strong>
      </AlertTitle>
      This post was archived on {formatDate(post.updated_at)} and not accessible publicly.
    </Alert>
  )
}
ArchivedBanner.propTypes = {
  post: PropTypes.object.isRequired,
  onRestore: PropTypes.func,
}
ArchivedBanner.defaultProps = {
  onRestore: () => {},
}

export default ArchivedBanner
