import reduce from 'image-blob-reduce'

export const imageMaxSize = 2000

export function getImagePreview(file, callback) {
  const fr = new FileReader()
  fr.onload = e => callback(e.target.result)
  fr.readAsDataURL(file)
}

export const resizeImage2k = async file => {
  const blob = await reduce().toBlob(file, { max: imageMaxSize })
  return new File([blob], file.name, { id: file.id, type: blob.type })
}
