import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import PrivateRouter from '../containers/PrivateRouter'
import PublicRouter from '../containers/PublicRouter'
import OpenRouter from '../containers/OpenRouter'
import Landing from '../pages/Landing'
import Login from '../pages/Login'
import SignUp from '../pages/SignUp'
import TwitterAuth from '../pages/TwitterAuth'
import PublicPost from '../pages/PublicPost'
import Home from '../pages/Home'
import Archives from '../pages/Archives'
import CreatePost from '../pages/CreatePost'
import UpdatePost from '../pages/UpdatePost'
import Profile from '../pages/Profile'
import Privacy from '../pages/Privacy'
import Terms from '../pages/Terms'
import Faqs from '../pages/Faqs'
import NotFound from '../pages/NotFound'
import Logout from '../pages/Logout'

export default function () {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          {/* Public exclusive */}
          <Route element={<PublicRouter />}>
            <Route path="/" element={<Landing />} />
            <Route path="/twitter-auth" element={<TwitterAuth />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
          </Route>

          {/* Private exclusive */}
          <Route element={<PrivateRouter />}>
            <Route path="/home" element={<Home />} />
            <Route path="/post/:id" element={<UpdatePost />} />
            <Route path="/new-post" element={<CreatePost />} />
            <Route path="/archives" element={<Archives />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/logout" element={<Logout />} />
          </Route>

          {/* Open routes */}
          <Route element={<OpenRouter />}>
            <Route path="/policy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/faqs" element={<Faqs />} />
            <Route path="/p/:shortID" element={<PublicPost />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  )
}
