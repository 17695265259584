import { PROFILE } from '../actions/types'
import { createEntityReducer } from './util'

const initialObjectState = {
  id: '',
  name: '',
  username: '',
  email: '',
  image: '',
  created_at: null,
  updated_at: null,

  stats: {
    posts: 0,
    images: 0,
    views: 0,
  },
}

export const profile = createEntityReducer(initialObjectState, PROFILE)

export default initialObjectState
