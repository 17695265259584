import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardMedia from '@material-ui/core/CardMedia'
import InputBase from '@material-ui/core/InputBase'
import LinearProgress from '@material-ui/core/LinearProgress'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import SetCoverIcon from '@material-ui/icons/Wallpaper'
import { uploadImage } from '../services/api'
import { getImagePreview, resizeImage2k } from '../util/image'
import { byteSize, uploadSizeLimitKB } from '../constants/post'
import Button from './Button'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1.5),
  },
  card: {
    [theme.breakpoints.down('sm')]: {
      minHeight: 150,
    },
    minHeight: 300,
  },
  action: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-evenly',
    },
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionButton: {
    color: theme.palette.text.secondary,
  },
  error: {
    textAlign: 'center',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}))

const defaultPayload = {
  id: '',
  done: false,
  error: false,
  file_id: '',
  caption: '',
}

function PostCreateImage(props) {
  const { image, title, file, isCover, onChange, onDelete, onSetCover } = props

  const [payload, setPayload] = React.useState(defaultPayload)
  const [preview, setPreview] = React.useState('')
  const [upload, setUpload] = React.useState({
    loading: true,
    error: null,
  })

  // Handles upload image when file is changed.
  React.useEffect(() => {
    if (file === undefined) {
      return
    }

    // Load file image preview.
    getImagePreview(file, p => setPreview(p))
    ;(async curFile => {
      // Resize image down
      const resized = await resizeImage2k(curFile)

      // Check file size limit
      if (resized.size > uploadSizeLimitKB * byteSize) {
        setUpload({
          loading: false,
          error: `file size limit reached ${Math.floor(
            resized.size / byteSize
          )} of ${uploadSizeLimitKB} KB`,
        })
        return
      }

      // Uploading file state
      const p = { ...defaultPayload, id: file.id }
      onChange(p)
      setPayload(p)

      // Process file upload
      try {
        const res = await uploadImage(resized)
        const pp = {
          ...payload,
          id: file.id,
          done: true,
          file_id: res.file_id,
        }
        onChange(pp)
        setPayload(pp)
        setUpload({ ...upload, loading: false })
      } catch (e) {
        const pp = {
          ...payload,
          id: file.id,
          done: true,
          error: true,
        }
        onChange(pp)
        setPayload(pp)
        setUpload({ loading: false, error: e.message })
      }
    })(file)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  const handleCaptionChange = e => {
    const p = { ...payload, caption: e.target.value }
    onChange(p)
    setPayload(p)
  }

  const { loading, error } = upload
  const disabled = !!error

  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        {/* Progress bar */}
        {loading && <LinearProgress />}

        {/* Error information */}
        {error && <div className={classes.error}>{error}</div>}

        {/* Image display */}
        <CardMedia
          component="img"
          image={image || preview}
          title={title}
          style={{ opacity: error ? 0.7 : 1 }}
        />

        {/* Image caption input */}
        <CardActions>
          <InputBase
            disabled={disabled}
            value={payload.caption}
            onChange={handleCaptionChange}
            fullWidth
            placeholder="Write a caption..."
            style={{ fontSize: '0.875rem' }}
          />
        </CardActions>
      </Card>

      {/* Image actions */}
      <div className={classes.action}>
        {!isCover && (
          <Button
            disabled={disabled}
            onClick={onSetCover}
            className={classes.actionButton}
            startIcon={<SetCoverIcon />}>
            Set as Cover
          </Button>
        )}

        <Button onClick={onDelete} className={classes.actionButton} startIcon={<DeleteIcon />}>
          Delete
        </Button>
      </div>
    </div>
  )
}
PostCreateImage.propTypes = {
  image: PropTypes.string,
  file: PropTypes.object,
  title: PropTypes.string,
  isCover: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onSetCover: PropTypes.func,
}
PostCreateImage.defaultProps = {
  image: '',
  file: null,
  title: '',
  isCover: false,
  onChange: () => {},
  onDelete: () => {},
  onSetCover: () => {},
}

export default PostCreateImage
