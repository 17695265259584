import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

export default function () {
  return (
    <div>
      <Skeleton animation="wave" variant="rect" height={300} />
      <Skeleton animation="wave" variant="text" />
      <br />
      <Skeleton animation="wave" variant="rect" height={300} />
      <Skeleton animation="wave" variant="text" />
      <br />
      <Skeleton animation="wave" variant="rect" height={300} />
      <Skeleton animation="wave" variant="text" />
    </div>
  )
}
