import React from 'react'
import PropTypes from 'prop-types'
import findIndex from 'lodash/findIndex'
import { makeStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import { arrayPullToHead } from '../util/array'
import PostUser from '../containers/PostUser'
import PostColumn from './PostColumn'
import PostCreateImage from './PostCreateImage'
import TagsInput from './TagsInput'
import Notify from './Notify'
import { maxImagesPerPost } from '../constants/post'
import AddPhotosButton from './AddPhotosButton'

const useStyles = makeStyles(theme => ({
  head: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  action: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-evenly',
    },
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionButton: {
    color: theme.palette.text.secondary,
  },
}))

function PostCreateForm({ onChange, images, onImageChange }) {
  const classes = useStyles()
  const [tags, setTags] = React.useState([])
  const [files, setFiles] = React.useState([])
  const [error, setError] = React.useState('')

  const [details, setDetails] = React.useState({
    title: '',
    description: '',
  })

  // Handle title and description changes
  const handleDetailsChanges = ({ target }) => {
    const { name, value } = target
    onChange({ ...details, tags, [name]: value })
    setDetails({ ...details, [name]: value })
  }

  // Handle tags changes
  const handleTagsChanges = values => {
    onChange({ ...details, tags: values })
    setTags(values)
  }

  // Handles file select to be uploaded.
  const handleFileSelect = newFiles => {
    let count = newFiles.length
    if (count === 0) {
      return
    }
    count += files.length
    if (count > maxImagesPerPost) {
      setError(`You reach maximum of ${count} / ${maxImagesPerPost} photos per post`)
      return
    }

    // Add new selected files to existing list.
    setFiles([...files, ...newFiles])
  }

  // Handle images update changes.
  const handleImageChanges = img => {
    const idx = findIndex(images, { id: img.id })
    if (img.error) {
      // remove error image
      images.splice(idx, 1)
      // update existing image
    } else if (idx !== -1) {
      images.splice(idx, 1, img)
    } else {
      // add new image
      images.push(img)
    }

    onImageChange(images)
  }

  // Handle image actions.
  const handleImageSetCover = (imgID, fileIndex) => {
    // using pick up the element and place it on index 0
    let ii = [...images]
    const idx = findIndex(ii, { id: imgID })
    ii = arrayPullToHead(ii, idx)
    onImageChange(ii)

    setFiles(arrayPullToHead([...files], fileIndex))

    // scroll transition to top when new cover is selected
    // window.scrollTo(0, 0)
    const anchor = document.querySelector('header')
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }
  const handleImageDelete = (imgID, fileIndex) => {
    const ii = [...images]
    const idx = findIndex(ii, { id: imgID })
    if (idx !== -1) {
      ii.splice(idx, 1)
      onImageChange(ii)
    }

    const ff = [...files]
    ff.splice(fileIndex, 1)
    setFiles(ff)
  }

  const handleErrorNotify = () => {
    setError('')
  }

  // const shouldDisplayBottomButton = document.body.scrollHeight > window.innerHeight + 200
  const shouldDisplayBottomButton = files.length > 1

  return (
    <PostColumn>
      <div className={classes.head}>
        {/* User details */}
        <PostUser />

        {/* Post details input */}
        <form onSubmit={e => e.preventDefault()}>
          <InputBase
            name="title"
            value={details.title}
            onInput={handleDetailsChanges}
            fullWidth
            autoFocus
            required
            placeholder="Post Title"
            style={{ fontSize: 20, fontWeight: 'bold', fontFamily: 'Raleway' }}
          />
          <InputBase
            name="description"
            value={details.description}
            onInput={handleDetailsChanges}
            fullWidth
            multiline
            placeholder="Your description here"
          />
        </form>
        <TagsInput value={tags} onChange={handleTagsChanges} />
        <br />
        <AddPhotosButton className={classes.actionButton} onSelect={handleFileSelect} />
        <br />
        <br />

        {/* Post images */}
        {files.map((f, i) => (
          <PostCreateImage
            key={f.id}
            onChange={handleImageChanges}
            onSetCover={() => handleImageSetCover(f.id, i)}
            onDelete={() => handleImageDelete(f.id, i)}
            file={f}
            isCover={i === 0}
          />
        ))}

        {shouldDisplayBottomButton && (
          <AddPhotosButton
            className={classes.actionButton}
            onSelect={handleFileSelect}
            automount={false}
          />
        )}
      </div>

      <Notify kind="error" open={error !== ''} onClose={handleErrorNotify}>
        {error}
      </Notify>
    </PostColumn>
  )
}
PostCreateForm.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  onImageChange: PropTypes.func,
}
PostCreateForm.defaultProps = {
  onChange: () => {},
  onImageChange: () => {},
}

export default PostCreateForm
