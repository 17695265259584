import React from 'react'
import { Link } from 'react-router-dom'
import HomeIcon from '@material-ui/icons/Apps'
import { isOk as isLoggedIn } from '../services/auth'
import HeaderBase from './HeaderBase'
import MoreMenu from './MoreMenu'
import Button from './Button'

function HeaderPublic() {
  const loggedIn = isLoggedIn()

  return (
    <>
      <HeaderBase loggedIn={loggedIn}>
        {loggedIn ? (
          <>
            <MoreMenu />
            &nbsp;&nbsp;
            <Button
              component={Link}
              to="/home"
              variant="contained"
              color="primary"
              startIcon={<HomeIcon />}>
              <strong>Home</strong>
            </Button>
          </>
        ) : (
          <>
            <Button component={Link} to="/login" color="primary">
              <strong>Login</strong>
            </Button>
            &nbsp;&nbsp;
            <Button component={Link} to="/" variant="contained" color="primary">
              <strong>Sign Up</strong>
            </Button>
          </>
        )}
      </HeaderBase>
    </>
  )
}

export default HeaderPublic
