import { all } from 'redux-saga/effects'
import auth from './auth'
import version from './version'
import profile from './profile'
import posts from './posts'

// notice how we now only export the rootSaga, single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([auth(), version(), profile(), posts()])
}
