import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from './Button'

function ConfirmDialog(props) {
  const { open, onClose, onConfirm, title, children } = props

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          <strong>Cancel</strong>
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          <strong>Ok</strong>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
ConfirmDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onConfirm: () => {},
}

export default ConfirmDialog
