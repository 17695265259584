import React from 'react'
import { Outlet } from 'react-router-dom'
import { isOk as isLoggedIn } from '../services/auth'
import ScrollTop from '../components/ScrollTop'
import AppNotify from './AppNotify'

export default function () {
  // Redirect to landing page if not logged in.
  if (!isLoggedIn()) {
    window.location = '/'
    return null
  }

  return (
    <div>
      <ScrollTop />

      {/* Page contents */}
      <Outlet />

      <AppNotify />
    </div>
  )
}
