import React from 'react'
import PropTypes from 'prop-types'

export const maxWidth = 640

function PostColumn({ children }) {
  return <div style={{ maxWidth, margin: '0 auto', minHeight: '65vh' }}>{children}</div>
}
PostColumn.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PostColumn
