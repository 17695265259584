import React from 'react'
import { Link } from 'react-router-dom'
import { isOk as isLoggedIn } from '../services/auth'
import HeaderBase from './HeaderBase'
import UploadButton from './UploadButton'
import MoreMenu from './MoreMenu'
import Button from './Button'

function Header() {
  const loggedIn = isLoggedIn()

  return (
    <>
      <HeaderBase loggedIn={loggedIn}>
        {loggedIn ? (
          <>
            <MoreMenu />
            &nbsp;&nbsp;
            <UploadButton />
          </>
        ) : (
          <>
            <Button component={Link} to="/login" color="primary">
              <strong>Login</strong>
            </Button>
            &nbsp;&nbsp;
            <Button component={Link} to="/" variant="contained" color="primary">
              <strong>Sign Up</strong>
            </Button>
          </>
        )}
      </HeaderBase>
    </>
  )
}

export default Header
