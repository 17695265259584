import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import MLink from '@material-ui/core/Link'
import Link from './Link'
import Copyright from './Copyright'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(8, 0, 8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3.5, 0, 3.5),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3.5, 0, 1),
    },
  },
  list: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1, 0),
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'space-evenly',
      flexWrap: 'wrap',
    },
    display: 'block',
    listStyle: 'none',
    padding: 0,
    margin: theme.spacing(1, 0, 0),
  },
  item: {
    [theme.breakpoints.down('xs')]: {
      float: 'none',
      marginRight: 0,
    },
    float: 'left',
    marginRight: theme.spacing(2),
  },
  copyright: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 1.5),
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      float: 'none',
      margin: theme.spacing(1.5),
    },
    float: 'right',
  },
}))

function Footer() {
  const classes = useStyles()

  return (
    <Container className={classes.root} maxWidth="md" disableGutters component="footer">
      <Divider />
      <ul className={classes.list}>
        <Zelda className={classes.item} to="/terms" target="_blank" rel="noreferrer">
          Terms
        </Zelda>
        <Zelda className={classes.item} to="/policy" target="_blank" rel="noreferrer">
          Privacy
        </Zelda>
        <Zelda className={classes.item} to="/faqs">
          FAQs
        </Zelda>
        <MLink className={classes.item} color="textSecondary" href="mailto:support@fotolink.app">
          Support
        </MLink>
      </ul>
      <Copyright className={classes.copyright} />
    </Container>
  )
}

function Zelda(props) {
  const { to, target, children, ...other } = props

  return (
    <li {...other}>
      <Link to={to} target={target}>
        <Typography variant="body2" color="textSecondary">
          {children}
        </Typography>
      </Link>
    </li>
  )
}
Zelda.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  target: PropTypes.string,
}
Zelda.defaultProps = {
  target: null,
}

export default Footer
