import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import difference from 'lodash/difference'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import NextIcon from '@material-ui/icons/Check'
import { postCreate, postClear, postSearch, appNotify } from '../actions'
import { maxImagesPerPost } from '../constants/post'
import HeaderBase from '../components/HeaderBase'
import Footer from '../components/Footer'
import PostCreateForm from '../components/PostCreateForm'
import Button from '../components/Button'
import MoreMenu from '../components/MoreMenu'
import ShareDialog from '../components/ShareDialog'
import { deleteImage } from '../services/api'
import * as Local from '../services/local'

function validatePayload(payload) {
  if (payload.title === '' || payload.description === '') {
    return 'Post title and description are required'
  }

  if (payload.images.length === 0) {
    return 'Post requires atleast 1 photo'
  }

  if (payload.images.length > maxImagesPerPost) {
    return `Post can only have maximum of ${maxImagesPerPost} photos`
  }

  // no error
  return false
}

function getImageSetDiff(a, b) {
  if (a === null) {
    return []
  }

  const mapA = a.map(img => img.file_id)
  if (b === null) {
    return mapA.map(v => ({ file_id: v }))
  }
  const mapB = b.map(img => img.file_id)

  return difference(mapA, mapB).map(v => ({ file_id: v }))
}

const DRAFT_IMAGES_KEY = 'draft_images'

const initialData = {
  title: '',
  description: '',
  tags: [],
}

function CreatePost(props) {
  const {
    createPost,
    clearPost,
    reloadCache,
    notifySuccess,
    notifyError,
    post,
    fetching,
    error,
  } = props

  const [didSubmit, setDidSubmit] = React.useState(false)
  const [data, setData] = React.useState(initialData)
  const [images, setImages] = React.useState([])
  const [uploading, setUploading] = React.useState(false)
  const [openShareDialog, setOpenShareDialog] = React.useState(false)

  const cleanUpUploadedImages = draftImages => {
    if (draftImages === null) {
      return
    }

    // console.log('cleaning up', draftImages)
    draftImages.forEach(img => {
      if (img.file_id === '') {
        return
      }

      deleteImage(img.file_id).catch(e => {
        console.warn('could not delete image file:', e)
      })
    })
    Local.remove(DRAFT_IMAGES_KEY)
  }

  // Handle image clean up draft images when page mounts
  React.useEffect(() => {
    const draft = Local.get(DRAFT_IMAGES_KEY)
    cleanUpUploadedImages(draft)
  }, [])

  // Handle post submit response.
  const navigate = useNavigate()
  React.useEffect(() => {
    if (error !== null) {
      notifyError(error)
      clearPost()
      return
    }

    if (!didSubmit || post.id === '') {
      return
    }

    setOpenShareDialog(true)
    Local.remove(DRAFT_IMAGES_KEY)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post, error])

  // Handle form submit
  const handleSubmit = () => {
    if (uploading) {
      return
    }

    // Format payload
    const payload = { ...data, images }

    // Validate payload
    const payloadError = validatePayload(payload)
    if (payloadError) {
      notifyError(payloadError)
      return
    }

    createPost(payload)
    setDidSubmit(true)
  }

  // Handle upload changes
  const handleImageChanges = values => {
    const total = values.length
    let done = 0
    for (let i = 0; i < total; i++) {
      const img = values[i]
      if (img.done) {
        done++
      }
    }

    setUploading(total !== done)
    setImages(values)
    // find removed draft images
    cleanUpUploadedImages(getImageSetDiff(Local.get(DRAFT_IMAGES_KEY), values))
    Local.save(DRAFT_IMAGES_KEY, values)
  }

  const handleCloseShareDialog = () => {
    setOpenShareDialog(false)
    reloadCache()
    clearPost()
    navigate(`/post/${post.id}`)
    notifySuccess(`${post.title} successfully created!`)
  }

  return (
    <>
      <HeaderBase loggedIn>
        <MoreMenu />
        {'  '}
        <Button
          onClick={handleSubmit}
          disabled={fetching}
          startIcon={
            uploading || fetching ? <CircularProgress size={20} color="inherit" /> : <NextIcon />
          }
          variant="contained"
          color="primary">
          <strong>Next</strong>
        </Button>
      </HeaderBase>

      <Container maxWidth="md" disableGutters style={{ minHeight: '65vh' }}>
        <PostCreateForm
          onChange={p => setData(p)}
          images={images}
          onImageChange={handleImageChanges}
        />
      </Container>

      <ShareDialog post={post} open={openShareDialog} onClose={handleCloseShareDialog} />
      <Footer />
    </>
  )
}

CreatePost.propTypes = {
  createPost: PropTypes.func.isRequired,
  clearPost: PropTypes.func.isRequired,
  reloadCache: PropTypes.func.isRequired,
  notifySuccess: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired,
  // post object reducer state
  post: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.string,
}
CreatePost.defaultProps = {
  error: null,
}

const mapStateToProps = ({ post }) => ({
  post: post.data,
  fetching: post.fetching,
  error: post.error,
})
const mapDispatchToProps = dispatch => ({
  createPost: payload => dispatch(postCreate.request(payload)),
  clearPost: () => dispatch(postClear()),
  reloadCache: () => dispatch(postSearch.reload()),
  notifySuccess: title => dispatch(appNotify.success(title)),
  notifyError: text => dispatch(appNotify.error(text)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreatePost)
