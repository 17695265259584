import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
// import bannerAd from '../assets/ads/speechhelo.png'

// Original ad size 728 x 90;
// <script data-cfasync='false' type='text/javascript' src='//p401879.clksite.com/adServe/banners?tid=401879_788813_2'></script>
// const adID = process.env.REACT_APP_AD_ID

let scriptInjected = false
// function injectAdScriptOnce() {
//   if (scriptInjected) {
//     return
//   }
//
//   const script = document.createElement('script')
//   script.src = `//p401879.clksite.com/adServe/banners?tid=${adID}`
//   script['data-cfasync'] = false
//   document.body.appendChild(script)
//   scriptInjected = true
// }
function injectAdScriptOnce() {
  if (scriptInjected) {
    return
  }
  const script = document.createElement('script')
  script.src = 'https://click.vendomedia.net/libs/mdwebclicks/v1.js'
  document.body.appendChild(script)
  scriptInjected = true
}

const useStyles = makeStyles(theme => ({
  text: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0.5),
    },
  },
  img: {
    width: '101%',
    margin: '-1px -2px -8px -1px',
  },
}))

export default function Ad() {
  const classes = useStyles()

  // if (!adID) {
  //   return null
  // }

  injectAdScriptOnce()

  return (
    <div>
      <Typography className={classes.text} variant="caption" color="textSecondary">
        Advertisement
      </Typography>
      {/* <script */}
      {/*  data-cfasync="false" */}
      {/*  type="text/javascript" */}
      {/*  src={`//p401879.clksite.com/adServe/banners?tid=${adID}`} */}
      {/* /> */}
      {/* <a href={adID} target="_blank" rel="noopener noreferrer"> */}
      <Card style={{ minHeight: 79, cursor: 'pointer' }}>
        <img
          id="md_displayad_1ad68f5d-5cf4-420a-b9eb-776ebcf61d1b"
          alt=""
          style={{ width: '100%', marginBottom: -7 }}
        />
        {/* <img src={bannerAd} alt="advertisement" style={{ width: '100%', marginBottom: -7 }} /> */}
      </Card>
      {/* </a> */}
    </div>
  )
}
