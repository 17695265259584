import React from 'react'
import PropTypes from 'prop-types'
import { Link as RLink } from 'react-router-dom'
import MLink from '@material-ui/core/Link'

function Link(props) {
  const { children, ...other } = props

  return (
    <MLink component={RLink} {...other} style={{ textDecoration: 'none', outline: 'none' }}>
      {children}
    </MLink>
  )
}
Link.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Link
