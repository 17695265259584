import React from 'react'
import PropTypes from 'prop-types'
import AddPhotosIcon from '@material-ui/icons/AddToPhotos'
import FileUploadButton from './FileUploadButton'
import Button from './Button'

export default function AddPhotosButton(props) {
  const { className, ...other } = props

  return (
    <FileUploadButton accept="image/*" multiple automount {...other}>
      <Button
        component="span"
        className={className}
        startIcon={<AddPhotosIcon />}
        fullWidth
        variant="outlined">
        Add Photos
      </Button>
    </FileUploadButton>
  )
}
AddPhotosButton.propTypes = {
  className: PropTypes.string,
}
AddPhotosButton.defaultProps = {
  className: null,
}
