import { combineReducers } from 'redux'
import { appNotify, appSettings } from './app'
import { auth } from './auth'
import { version } from './version'
import { profile } from './profile'
import { postSearch, post, publicPost } from './posts'

const rootReducer = combineReducers({
  // Application states.
  appNotify,
  appSettings,
  // Service user states.
  auth,
  profile,
  version,
  // Service resource states.
  postSearch,
  post,
  publicPost,
  // userSearch,
  // user,
})

export default rootReducer
