import React from 'react'
import PropTypes from 'prop-types'
import uniqueId from 'lodash/uniqueId'

function FileUploadButton(props) {
  const { children, automount, onSelect, ...other } = props

  const [open, setOpen] = React.useState(automount)

  const handleSelect = e => {
    const files = []

    const f = e.target.files
    for (let i = 0; i < f.length; i++) {
      const ff = f[i]
      ff.id = uniqueId()
      files.push(ff)
    }

    onSelect(files)
  }

  const handleRef = e => {
    if (!open) {
      return
    }

    if (e === null || e.files.length !== 0) {
      return
    }

    // Mount once
    setOpen(!open)
    e.click()
  }

  const uid = `file-upload-${uniqueId()}`

  return (
    <>
      <input
        {...other}
        id={uid}
        style={{ display: 'none' }}
        ref={handleRef}
        onChange={handleSelect}
        type="file"
      />
      <label htmlFor={uid}>{children}</label>
    </>
  )
}
FileUploadButton.propTypes = {
  children: PropTypes.node.isRequired,
  onSelect: PropTypes.func,
  automount: PropTypes.bool,
}
FileUploadButton.defaultProps = {
  onSelect: () => {},
  automount: false,
}

export default FileUploadButton
