import { takeLatest, all, call, put } from 'redux-saga/effects'
import * as Local from '../services/local'
import * as Api from '../services/api'
import * as Actions from '../actions'
import { PROFILE } from '../actions/types'

const cacheKey = 'profile'
const cacheTTL = 60 * 5

function* get() {
  // Check for cache hit and use that
  let result = Local.get(cacheKey)
  if (result !== null) {
    // Get new stats value for real-time stats effect
    if (result.stats.views < 1000) {
      result.stats = yield Api.userStats()
    }
    yield put(Actions.profileGet.success(result))
    return
  }

  // No hit, request for a new one
  try {
    const user = yield call(Api.userProfile.GET)
    const stats = yield Api.userStats()
    result = { ...user, stats }
    // Cache result and proceed
    yield Local.save(cacheKey, result, cacheTTL)
    yield put(Actions.profileGet.success(result))
  } catch (e) {
    yield put(Actions.profileGet.failure(e.message))
  }
}

function* update({ profile }) {
  try {
    const result = yield call(Api.userProfile.PATCH, profile)
    yield put(Actions.profileUpdate.success(result))

    // Invalidate cache and get updated values
    yield Local.remove(cacheKey)
    yield put(Actions.profileGet.request())
  } catch (e) {
    yield put(Actions.profileUpdate.failure(e.message))
  }
}

export default function* () {
  yield all([takeLatest(PROFILE.GET.REQUEST, get), takeLatest(PROFILE.UPDATE.REQUEST, update)])
}
