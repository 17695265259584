import React from 'react'
import PropTypes from 'prop-types'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

let childrenCache = null

function Notify(props) {
  const { kind, autoHideDuration, children, open, onClose } = props

  const handleClose = (e, reason) => {
    onClose(e, reason)

    // added delay to prevent emptying text effect.
    setTimeout(() => {
      childrenCache = null
    }, 200)
  }

  // lingering child value value
  if (children) {
    childrenCache = children
  }

  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
      <Alert onClose={onClose} severity={kind}>
        {childrenCache}
      </Alert>
    </Snackbar>
  )
}
Notify.propTypes = {
  children: PropTypes.node.isRequired,
  kind: PropTypes.string,
  autoHideDuration: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
Notify.defaultProps = {
  kind: 'info',
  open: false,
  autoHideDuration: 6000,
  onClose: () => {},
}

export default Notify

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
