// IMPORTANT RULE! ONLY used this utility file within its folder.

export function formatError(err) {
  if (err === null) {
    return 'Something went wrong!'
  }

  // Append period if not exist.
  return err.endsWith('.') ? err : `${err}.`
}

// Creates basic entity object reducer.
export function createEntityReducer(
  initialObjectState,
  entityActionType,
  objectFormatter = o => o
) {
  const defaultState = {
    data: initialObjectState,
    fetching: false,
    error: null,
  }

  return (state = defaultState, action) => {
    switch (action.type) {
      // API call request actions
      case entityActionType.GET.REQUEST:
      case entityActionType.CREATE.REQUEST:
      case entityActionType.UPDATE.REQUEST:
      case entityActionType.REMOVE.REQUEST:
        return {
          ...state,
          fetching: true,
          error: null,
        }

      // API call success actions
      case entityActionType.GET.SUCCESS:
      case entityActionType.CREATE.SUCCESS:
      case entityActionType.UPDATE.SUCCESS:
      case entityActionType.REMOVE.SUCCESS:
        return {
          ...state,
          data: objectFormatter(action.result),
          fetching: false,
          error: null,
        }

      // API call failure actions
      case entityActionType.GET.FAILURE:
      case entityActionType.CREATE.FAILURE:
      case entityActionType.UPDATE.FAILURE:
      case entityActionType.REMOVE.FAILURE:
        return {
          ...state,
          fetching: false,
          error: formatError(action.error),
        }

      // Reset to default state.
      case entityActionType.CLEAR:
        return defaultState

      default:
        return state
    }
  }
}

const defaultSearchState = {
  filter: {},
  fetching: false,
  error: null,
  data: [],
  meta: {
    resultCount: 0,
    totalCount: 0,
  },
}

// Creates basic search reducer.
export function createSearchReducer({ REQUEST, SUCCESS, FAILURE }) {
  return (state = defaultSearchState, action) => {
    switch (action.type) {
      case REQUEST:
        return {
          ...state,
          fetching: true,
          error: null,
          filter: action.filter,
          // HOTFIX: Support paging/infinite loading for active grid list and
          // archive grid list - clearing previous result.
          data: state.filter.status === action.filter.status ? state.data : [],
        }
      case SUCCESS:
        return {
          ...state,
          fetching: false,
          error: null,
          data: action.result.data,
          meta: {
            resultCount: action.result.result_count,
            totalCount: action.result.total_count,
          },
        }
      case FAILURE:
        return {
          ...state,
          fetching: false,
          error: action.error,
        }
      default:
        return state
    }
  }
}

// Create generic request reducer.
export function createRequestReducer(initialObjectState, actionType) {
  const defaultState = {
    data: initialObjectState,
    fetching: false,
    error: null,
  }

  return (state = defaultState, action) => {
    switch (action.type) {
      case actionType.REQUEST:
        return {
          ...state,
          fetching: true,
          error: null,
        }
      case actionType.SUCCESS:
        return {
          ...state,
          data: action.result,
          fetching: false,
          error: null,
        }
      case actionType.FAILURE:
        return {
          ...state,
          fetching: false,
          error: formatError(action.error),
        }
      default:
        return state
    }
  }
}
