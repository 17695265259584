import React from 'react'
import PropTypes from 'prop-types'
import brand from '../assets/brand.png'

const defaultBrandSize = 153

function Brand({ size, ...other }) {
  return <img {...other} src={brand} alt="" width={size} />
}
Brand.propTypes = {
  size: PropTypes.number,
}
Brand.defaultProps = {
  size: defaultBrandSize,
}

export default Brand
