import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Hero from '../components/Hero'
import { profileGet, appNotify } from '../actions'

function ProfileHero(props) {
  const { getProfile, notifyError, profile, fetching, error } = props

  React.useEffect(() => {
    if (error !== null) {
      notifyError(error)
      return
    }

    getProfile()
  }, [getProfile, notifyError, error])

  return <Hero profile={profile} loading={fetching} />
}
ProfileHero.propTypes = {
  getProfile: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired,
  // profile object reducer state.
  profile: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.string,
}
ProfileHero.defaultProps = {
  error: null,
}

const mapStateToProps = ({ profile }) => ({
  profile: profile.data,
  fetching: profile.fetching,
  error: profile.error,
})
const mapDispatchToProps = dispatch => ({
  getProfile: () => dispatch(profileGet.request()),
  notifyError: text => dispatch(appNotify.error(text)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileHero)
