import React from 'react'
import PropTypes from 'prop-types'
import InputBase from '@material-ui/core/InputBase'
import Component from 'react-tagsinput'
import Tag from './Tag'

// function renderTag(props) {
//   // eslint-disable-next-line react/prop-types
//   const { tag, key, getTagDisplayValue } = props
//
//   const value = getTagDisplayValue(tag)
//   const hasSpaced = value.split(' ').length > 1
//   return (
//     <Typography
//       key={key}
//       style={{
//         marginRight: 8,
//         borderBottom: hasSpaced ? '1px solid lightblue' : 'none',
//       }}
//       color="textSecondary"
//       component="span"
//       variant="body2">
//       #{value}
//     </Typography>
//   )
// }

function renderTagChip(props) {
  // eslint-disable-next-line react/prop-types
  const { tag, key, getTagDisplayValue } = props

  const value = getTagDisplayValue(tag)
  return (
    <Tag
      key={key}
      label={value}
      style={{
        marginRight: 8,
        marginBottom: 4,
      }}
      variant="outlined"
      size="small"
    />
  )
}

function renderInput(props) {
  // eslint-disable-next-line react/prop-types
  const { onChange, value, addTag, ...other } = props

  return (
    <InputBase
      onChange={onChange}
      value={value}
      {...other}
      placeholder="tags"
      style={{ fontSize: '0.875rem', paddingLeft: 0 }}
    />
  )
}

function TagsInput(props) {
  const { value, onChange } = props

  const [tags, setTags] = React.useState(value)

  // Handle tags changes
  const handleTagsChanges = values => {
    // clean tags
    const clean = values.map(v => v.replace('#', '').trim())
    setTags(clean)
    onChange(clean)
  }

  return (
    <Component
      value={tags}
      onChange={handleTagsChanges}
      renderTag={renderTagChip}
      renderInput={renderInput}
      maxTags={10}
      onlyUnique
      addOnBlur
    />
  )
}
TagsInput.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
}
TagsInput.defaultProps = {
  value: [],
  onChange: () => {},
}

export default TagsInput
