import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router'
import { connect } from 'react-redux'
import { publicPostGet, appNotify } from '../actions'
import Component from '../components/PublicPostContent'

function PublicPostContent(props) {
  const { getPublicPost, notifyError, publicPost, fetching, error } = props

  const postShortID = useParams().shortID

  React.useEffect(() => {
    if (error !== null) {
      notifyError(error)
      return
    }

    getPublicPost(postShortID)
  }, [getPublicPost, notifyError, postShortID, error])

  return <Component post={publicPost} loading={fetching} error={error} />
}
PublicPostContent.propTypes = {
  getPublicPost: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired,
  // post object reducer state
  publicPost: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.string,
}
PublicPostContent.defaultProps = {
  error: null,
}

const mapStateToProps = ({ publicPost }) => ({
  publicPost: publicPost.data,
  fetching: publicPost.fetching,
  error: publicPost.error,
})
const mapDispatchToProps = dispatch => ({
  getPublicPost: shortID => dispatch(publicPostGet.request(shortID)),
  notifyError: text => dispatch(appNotify.error(text)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PublicPostContent)
