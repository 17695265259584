import { createRequestTypes, createEntityActionTypes } from './util'

export const APP_NOTIFY = 'APP_NOTIFY'
export const APP_SETTINGS_SET = 'APP_SETTINGS_SET'
export const APP_SETTINGS_GET = 'APP_SETTINGS_GET'

export const VERSION = createRequestTypes('VERSION')
export const PUBLIC_POST = createRequestTypes('PUBLIC_POST')
export const AUTH = createRequestTypes('AUTH')
export const PROFILE = createEntityActionTypes('PROFILE')
export const POST = createEntityActionTypes('POST')
