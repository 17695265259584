import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Component from '../components/PostGrid'
import { postSearch } from '../actions'
import { postStatusArchived } from '../constants/post'

const scrollBias = 200

function PostGrid(props) {
  const { filter: defaultFilter, posts, totalPosts, fetching, error, getPosts } = props

  const [filter, setFilter] = React.useState(defaultFilter)

  // Get initial posts
  React.useEffect(() => {
    if (error !== null) {
      return
    }

    getPosts(filter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLoadMore = () => {
    if (posts.length >= totalPosts) {
      return
    }

    const limit = filter.limit + defaultFilter.limit
    // const ff = { ...filter, page }
    const ff = { ...filter, limit }
    setFilter(ff)
    getPosts(ff)
  }

  // Handle infinite scroll for loading more
  React.useEffect(() => {
    const listener = () => {
      if (fetching || window.scrollY + scrollBias < window.scrollMaxY) {
        return
      }

      handleLoadMore()
    }

    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  })

  // Get variant value
  let variant
  if (filter.status && filter.status === postStatusArchived) {
    variant = 'archive'
  }

  return (
    <Component
      variant={variant}
      posts={posts}
      loading={fetching}
      error={error}
      onLoadMore={handleLoadMore}
      totalPosts={totalPosts}
    />
  )
}
PostGrid.propTypes = {
  filter: PropTypes.object,
  // reducer injected props.
  getPosts: PropTypes.func.isRequired,
  // profile object reducer state.
  posts: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalPosts: PropTypes.number.isRequired,
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.string,
}
PostGrid.defaultProps = {
  error: null,
  filter: {},
}

const mapStateToProps = ({ postSearch: postList }) => ({
  posts: postList.data,
  totalPosts: postList.meta.totalCount,
  fetching: postList.fetching,
  error: postList.error,
})
const mapDispatchToProps = dispatch => ({
  getPosts: filter => dispatch(postSearch.request(filter)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PostGrid)
