import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardMedia from '@material-ui/core/CardMedia'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import MButton from '@material-ui/core/Button'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputBase from '@material-ui/core/InputBase'
import CloseIcon from '@material-ui/icons/Close'
import useFormInput from '../util/useFormInput'
import { imageThumbSizeSM } from '../constants/image'
import Button from './Button'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  card: {
    [theme.breakpoints.down('sm')]: {
      minHeight: 150,
    },
    minHeight: 300,
  },
}))

const dialogTitle = 'Caption Update'

function CaptionUpdateDialog(props) {
  const { image, open, onClose, onSubmit } = props

  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const captionInput = useFormInput(image.caption)

  const handleSubmit = () => {
    onSubmit(captionInput.value)
    onClose()
  }

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      {fullScreen ? (
        <AppBar className={classes.appBar} variant="outlined">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {dialogTitle}
            </Typography>
            <MButton color="inherit" onClick={handleSubmit}>
              Save
            </MButton>
          </Toolbar>
        </AppBar>
      ) : (
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      )}

      <DialogContent style={fullScreen ? { paddingLeft: 0, paddingRight: 0 } : null}>
        <Card className={classes.card}>
          <CardMedia
            component="img"
            image={`${imageThumbSizeSM}/${image.file_id}`}
            title={image.caption}
          />
          <CardActions>
            <InputBase
              {...captionInput}
              fullWidth
              placeholder="Write a caption..."
              style={{ fontSize: '0.875rem' }}
            />
          </CardActions>
        </Card>
      </DialogContent>

      {!fullScreen && (
        <DialogActions>
          <Button onClick={onClose} color="primary">
            <strong>Cancel</strong>
          </Button>
          <Button onClick={handleSubmit} color="primary">
            <strong>Save</strong>
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
CaptionUpdateDialog.propTypes = {
  image: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}
CaptionUpdateDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onSubmit: () => {},
}

export default CaptionUpdateDialog
