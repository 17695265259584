import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Container from '@material-ui/core/Container'
import Link from './Link'
import Brand from './Brand'

const useStyles = makeStyles(theme => ({
  appBar: {
    marginBottom: theme.spacing(2.5),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1.5, 0),
    },
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    textTransform: 'none',
    fontWeight: 'bold',
  },
  brand: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    cursor: 'pointer',
    marginTop: 4,
    filter: theme.palette.type === 'dark' ? 'invert(1)' : 'none',
  },
}))

function HeaderBase({ loggedIn, variant, children }) {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  let appBarStyleVariant = {}
  if (variant === 'seamless') {
    appBarStyleVariant = {
      borderBottom: 'none',
      background: 'none',
    }
  }

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      className={classes.appBar}
      style={appBarStyleVariant}>
      <Container maxWidth="md" disableGutters>
        <Toolbar className={classes.toolbar} disableGutters>
          <Link to={loggedIn ? '/home' : '/'}>
            <Brand className={classes.brand} size={isMobile ? 103 : 123} />
          </Link>
          <span className={classes.toolbarTitle} />
          <nav>{children}</nav>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
HeaderBase.propTypes = {
  children: PropTypes.node,
  loggedIn: PropTypes.bool,
  variant: PropTypes.string,
}
HeaderBase.defaultProps = {
  children: null,
  loggedIn: false,
  variant: 'default',
}

export default HeaderBase
