import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import MuiLink from '@material-ui/core/Link'
import BrandBig from '../components/Brand'
import Footer from '../components/Footer'
import Link from '../components/Link'
import LoginButton from '../components/LoginButton'
import Heading from '../components/Heading'
import screenLight from '../assets/screen.mp4'
import screenDark from '../assets/screen-dark.mp4'
import { Divider } from '@material-ui/core'
// import ScreenDemo from '../components/ScreenDemo'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2),
      textAlign: 'center',
      marginTop: '1vh',
    },
    [theme.breakpoints.down('xs')]: {},
    margin: theme.spacing(12, 0, 0),
  },
  card: {
    [theme.breakpoints.down('xs')]: {
      background: 'none',
      boxShadow: 'none',
    },
    margin: '0 auto',
    maxWidth: theme.breakpoints.values.md / 2,
    padding: theme.spacing(2),
  },
  brand: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 2, 6),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
    },
    margin: theme.spacing(5, 0, 0, 0),
    filter: theme.palette.type === 'dark' ? 'invert(1)' : 'none',
  },
  heading: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 45,
      marginTop: theme.spacing(8),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '8vw',
      marginTop: theme.spacing(4),
    },
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
  content: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      marginBottom: theme.spacing(3),
    },
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  googlePlayButton: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    width: 200,
  },
  caption: {},
  screen: {
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
    overflow: 'hidden',
    maxWidth: 530,
  },
}))

function Landing() {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div>
      {/* <Header /> */}
      <Container maxWidth="md" disableGutters>
        <Link to="/">
          <BrandBig className={classes.brand} size={isMobile ? 123 : 153} />
        </Link>
        <span
          className={classes.brand}
          style={{ float: 'right', fontWeight: 'bold', fontSize: 'large' }}>
          <MuiLink href="https://www.youtube.com/watch?v=D2SAmxAAldk" target="_blank">
            Watch our video
          </MuiLink>
        </span>
        {/* <span className={classes.brand} style={{ float: 'right' }}>
          <Link to="/how-it-works">How it works</Link>
        </span> */}

        <Grid container className={classes.root}>
          <Grid item md={7} sm={12}>
            <Splash
              className={classes.screen}
              style={{ display: theme.palette.type === 'light' ? 'block' : 'none' }}
              src={screenLight}
            />
            <Splash
              className={classes.screen}
              style={{ display: theme.palette.type === 'dark' ? 'block' : 'none' }}
              src={screenDark}
            />
            {/* <ScreenDemo /> */}
          </Grid>
          <Grid item md={5} sm={12}>
            <Heading className={classes.heading} variant="h2">
              Upload. Preview. Share.
            </Heading>
            <br />
            <Typography className={classes.content} variant="h6" color="textSecondary">
              FotoLink makes your photo collections share easily via tweets and permalinks.
            </Typography>

            <LoginButton className={classes.button} />
            <br />
            <Typography className={classes.caption} variant="body2" color="textSecondary">
              By clicking continue, you agree to our&nbsp;
              <Link to="/terms" target="_blank">
                Terms of Service
              </Link>
              {' and '}
              <Link to="/policy" target="_blank">
                Privacy Policy
              </Link>
              .
            </Typography>

            <br />
            <Divider variant="middle" />

            <p align="center">
              <a href="https://play.google.com/store/apps/details?id=app.fotolink.mobile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  className={classes.googlePlayButton}
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                />
              </a>
            </p>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  )
}

// eslint-disable-next-line react/prop-types
function Splash({ src, ...other }) {
  return (
    <div {...other}>
      <video
        style={{ width: '100%', marginTop: -4, marginBottom: -8 }}
        autoPlay
        loop
        playsInline
        muted>
        <source src={src} type="video/mp4" />
      </video>
    </div>
  )
}

export default Landing
