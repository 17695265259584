import React from 'react'
import HeaderPublic from '../components/HeaderPublic'
import Copyright from '../components/Copyright'
import PublicPostContent from '../containers/PublicPostContent'

function ViewPost() {
  return (
    <div>
      <HeaderPublic />

      <PublicPostContent />

      <Copyright align="center" style={{ paddingTop: 8 * 2 }} />
    </div>
  )
}

export default ViewPost
